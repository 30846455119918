// This import is required if you are defining react components in this module.
import React, { useState, useEffect } from 'react';

import * as GlobalVariableContext from '../config/GlobalVariableContext';

//import { Text } from 'react-native';

import { Calendar, CalendarList, Agenda } from 'react-native-calendars';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <CustomCode.MyExampleComponent />

const __NonDispoDates = {
  '2023-07-16': { selected: true, marked: true, selectedColor: 'blue' },
  '2023-07-17': { marked: true },
  '2023-07-18': { marked: true, dotColor: 'red', activeOpacity: 0 },
  '2023-07-19': { disabled: true, disableTouchEvent: true },
  '2023-08-16': { selected: true, marked: true, selectedColor: 'red' },
  '2023-08-17': { selected: true, marked: true, selectedColor: 'red' },
  '2023-08-18': { selected: true, marked: true, selectedColor: 'red' },
  '2023-08-19': { selected: true, marked: true, selectedColor: 'red' },
  '2023-08-20': { selected: true, marked: true, selectedColor: 'red' },
  '2023-08-21': { selected: true, marked: true, selectedColor: 'red' },
  '2023-08-22': { selected: true, marked: true, selectedColor: 'red' },
};

export const DisposCalendarList = ({
  setStartOfBooking,
  setEndOfBooking,
  navigation,
}) => {
  const Constants = GlobalVariableContext.useValues();
  const Variables = Constants;
  const setGlobalVariable = GlobalVariableContext.useSetValue();

  const [strtDate, setstrtDate] = useState('');
  const [endDate, setendDate] = useState('');

  const _NonDispoDates = Variables.ResaNonDispoDates; //[0];
  const _bookedDates = { ..._NonDispoDates };
  const [markedDates, setmarkedDates] = useState(_bookedDates);
  useEffect(() => {
    setmarkedDates(markedDates);
  }, [markedDates]);

  const getDateForCalendar = date => {
    const yr = date.getFullYear();
    const month = `${date.getMonth() + 1 < 10 ? 0 : ''}${date.getMonth() + 1}`;
    const d = `${date.getDate() < 10 ? 0 : ''}${date.getDate()}`;
    return `${yr}-${month}-${d}`;
  };

  const getDateForEuroDisp = strDate => {
    //received in yyyy-mm-dd format returned in dd-mm-yyyy
    let elems = strDate.split('-');
    const yr = elems[0];
    const month = elems[1];
    const d = elems[2];
    return `${d}-${month}-${yr}`;
  };
  const getDateFromEuroDisp = strDate => {
    //received in dd-mm-yyyy  format returned in yyyy-mm-dd
    let elms = strDate.split('-');
    const d = elms[0];
    const month = elms[1];
    const yr = elms[2];
    return `${yr}-${month}-${d}`;
  };

  const switchDatesBetween = (fromDate, toDate, isOn) => {
    let _fromDate = new Date(fromDate);
    let _endDate = new Date(toDate);
    let curDate = new Date(_fromDate.getTime());
    //console.log('range::toDate ==> '+toDate)
    if (toDate) {
      //} !== "") {
      console.log('Calc range dates..5..');
      while (curDate < _endDate) {
        curDate = new Date(curDate.setDate(curDate.getDate() + 1));
        console.log('Loop::curDate ==> ' + curDate);
        let _curDate = getDateForCalendar(curDate);
        if (_curDate === toDate) continue;
        console.log('Loop::CalendarDate::_curDate ==> ' + _curDate);
        markedDates[_curDate] = isOn
          ? {
              //startingDay: true,
              //endingDay: true,
              selected: true,
              marked: true,
              selectedColor: 'orange', // '#969696'
              textColor: 'black',
            }
          : {};
      }
    }
  };

  const dayPressed = day => {
    let toDay = new Date();
    let slctd = new Date(day.dateString);
    const { dateString } = day;

    if (slctd.getTime() < toDay.getTime()) {
      alert('Selected date has already passed, try another...');
      return 0;
      if (markedDates[`${dateString}`].selectedColor === 'orange') {
        console.log(
          'Past selected Orange date : ' + day.dateString + ' reclicked !!!'
        );
        if (markedDates[`${dateString}`].startingDay === true) {
          //switchDatesBetween(strtDate,endDate,false);
          setstrtDate('');
        }
        markedDates[`${dateString}`] = {};
        const updatedMarkedDates = { ...markedDates };
        setmarkedDates(updatedMarkedDates);
      } else {
        alert('Selected date has already passed, try another...');
      }
      //return 0;
    }
    console.log('selected day', day.dateString); //
    //alert('selected day', day.year+'-'+day.month+'-'+day.day);//console.log

    //this.state.
    const isMarkedBefore = !!(
      markedDates[`${dateString}`] && markedDates[`${dateString}`].selected
    );

    //markedDates[`${dateString}`] = { selected: !isMarkedBefore };

    if (!isMarkedBefore) {
      // new marking
      //console.log('start date =1=> '+strtDate)
      if (strtDate == '') {
        markedDates[`${dateString}`] = {
          selected: true,
          startingDay: true,
          marked: true,
          selectedColor: 'orange',
        };

        let _strtDate = day.dateString;
        //let euroDisp = getDateForEuroDisp(_strtDate);
        //let calDisp = getDateFromEuroDisp(euroDisp);
        //console.log('Cal Disp ==> '+calDisp);
        setStartOfBooking(getDateForEuroDisp(_strtDate));

        setstrtDate(_strtDate);
        if (endDate !== '') {
          // reconstruct the in between dates range selection
          switchDatesBetween(_strtDate, endDate, true);
        }
        // a new immutable param with spread notation ... here its updatedMarkedDates
        const updatedMarkedDates = { ...markedDates };

        // Triggers component to render again, picking up the new state
        //console.log('markedDates ===> '+JSON.stringify(markedDates));
        setmarkedDates(updatedMarkedDates); ////this.setState({ _markedDates: updatedMarkedDates });
      } else {
        // selecting End Date

        let slctd = new Date(day.dateString);
        let strt = new Date(strtDate);
        if (slctd < strt) {
          alert('Selected end date is invalid, try again...');
          return 0;
        }

        //this.state.markedDates = markedDates;
        let _endDate = day.dateString;
        setendDate(_endDate);
        setEndOfBooking(getDateForEuroDisp(_endDate));

        // get inner range dates between Start/End dates :
        switchDatesBetween(strtDate, _endDate, true);
        console.log('rangeDates ===> ' + JSON.stringify(markedDates));

        markedDates[`${dateString}`] = {
          endingDay: true,
          selected: true,
          marked: true,
          selectedColor: 'orange',
        };
        updatedMarkedDates = { ...markedDates };
        console.log(
          'markedDates & rangeDates ===> ' + JSON.stringify(updatedMarkedDates)
        );
        setmarkedDates(updatedMarkedDates);
      }
    } else {
      if (markedDates[`${dateString}`].selectedColor === 'orange') {
        console.log('Orange date : ' + day.dateString + ' reclicked !!!');
        if (markedDates[`${dateString}`].startingDay === true) {
          switchDatesBetween(strtDate, endDate, false);
          setstrtDate('');
          setStartOfBooking('');
        }

        if (markedDates[`${dateString}`].endingDay === true) {
          switchDatesBetween(strtDate, endDate, false);
          setendDate('');
          setEndOfBooking('');
        }
        // If marked is in Between Start & End : clear from (start+1) til end :
        let _strt = new Date(strtDate).getTime();
        let _end = new Date(endDate).getTime();
        let _btwn = new Date(`${dateString}`).getTime();
        if (_btwn > _strt && _btwn < _end) {
          switchDatesBetween(strtDate, endDate, false);
          //reset end date :-
          markedDates[`${endDate}`] = {};
          setendDate('');
          setEndOfBooking('');
        }

        markedDates[`${dateString}`] = {};
        //const updatedMarkedDates = {...markedDates.splice(markedDates.indexOf(`${dateString}`), 1)};
        const updatedMarkedDates = { ...markedDates };
        setmarkedDates(updatedMarkedDates);

        console.log('markedDates ===> ' + JSON.stringify(markedDates));
      } else
        alert(
          'Selected date ' + day.dateString + ' is already booked, try another'
        );
    }
  };

  let toDay = new Date();
  let dayDate = getDateForCalendar(toDay);
  let minDte = Variables.VacSeasonStart;
  let maxDte = Variables.VacSeasonEnd;
  return (
    <Calendar
      //firstDay={1}
      //initialDate={dayDate}
      //current = {dayDate}
      minDate={minDte} //{'2022-06-01'}
      maxDate={maxDte} //{'2023-11-30'}
      onDayPress={day => {
        dayPressed(day);
      }}
      onDayLongPress={day => {
        console.log('longpress selected day', day.dateString);
      }}
      onMonthChange={month => {
        console.log('month changed', month);
        //firstDay={} // current={}
        //initialDate={}
        //console.log('markedDates ===> '+JSON.stringify(markedDates));
      }}
      hideExtraDays={true}
      theme={{
        arrowColor: 'black',
      }}
      onPressArrowLeft={subtractMonth => subtractMonth()}
      onPressArrowRight={addMonth => addMonth()}
      //markingType={"period"} // not good it takes out the highlight.
      markedDates={markedDates}
      enableSwipeMonths={true}
      monthFormat={'MMMM yyyy '}
    />
  );
};
