import palettes from '../themes/palettes';
// This import is required if you are defining react components in this module.
import React from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { Text, Image } from 'react-native';

import Images from '../config/Images';
import * as ImgDico from '../custom-files/ImageDico.js';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <DicoData.MyExampleComponent />
export const MakeJsx = props => {
  const { theme } = props;
  const { itemId } = props;
  const { itemText } = props;
  const { imgUri } = props;
  const { scrollCurrIdx } = props;

  ////console.log ("FillScrollCardSectionJSX::imgUri ==> "+imgUri);

  const currImage = imgStr => {
    //  cardData[itemId-1].imgUri;
    //return (dico[imgStr]); // { , }
    const theImg = ImgDico.Dico[imgStr];
    if (typeof theImg === 'string') {
      console.log('Section imgUri =CloudImg=> ' + theImg);
      return { uri: theImg };
    } else return theImg; //const theImg =  ImgDico.Dico[imgStr];
  };

  const theText = itemText; //cardData[itemId-1].itemText;
  const theImg = currImage(imgUri); // ImgDico.Dico[imgUri];//  cardData[itemId-1].imgUri;

  const stdColor = theme.colors.text.strong;
  const hilightColor = palettes.Brand['Strong Inverse'];

  const theColor = itemId == scrollCurrIdx ? hilightColor : stdColor;
  const fontDetails =
    itemId == scrollCurrIdx ? 'Poppins_600SemiBold' : 'Poppins_400Regular';

  return (
    <>
      <Image
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          height: 100,
          width: '100%',
        }}
        resizeMode={'cover'}
        source={theImg}
      />
      <Text
        style={{
          color: theColor,
          fontFamily: fontDetails,
          marginTop: 5,
          textAlign: 'center',
        }}
      >
        {theText}
      </Text>
    </>
  );
};
