import React from 'react';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Sharing from 'expo-sharing';
import { Image, ImageBackground, ScrollView, Text, View } from 'react-native';
import * as CustomCode from '../CustomCode';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as DrbSwiper from '../custom-files/DrbSwiper';
import * as ExpoFileSys from '../custom-files/ExpoFileSys';
import * as FillScrollCardSectionJSX from '../custom-files/FillScrollCardSectionJSX';
import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct';
import * as ScrollFillWholeCardView from '../custom-files/ScrollFillWholeCardView';
import * as SetSectionTitle from '../custom-files/SetSectionTitle';
import * as scrollViewItemHiLightOnSelect from '../custom-files/scrollViewItemHiLightOnSelect';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SwiperSectorExplorerScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [img2Zoom, setImg2Zoom] = React.useState('');
  const [itemsIdArr, setItemsIdArr] = React.useState([
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
  ]);
  const [listChanged, setListChanged] = React.useState(false);
  const [scrollCurrIdx, setScrollCurrIdx] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textAreaValue2, setTextAreaValue2] = React.useState('');
  // set section data for a specific sector
  const setSectSectionData = (
    Variables,
    setGlobalVariableValue,
    sectorNum,
    sectionNum
  ) => {
    let sectObj = GetSwiperSectorStruct.SectorData(sectorNum); //1
    let sectorData = sectObj.sectorData;
    //alert(" sectDta ==>" +JSON.stringify(sectorData) );//alert("Sector Title => "+sectorData.SectorTitle);//alert("Secont(1) => "+JSON.stringify(sectorData.Sections[0]))
    let sectionObj = sectorData.Sections[sectionNum - 1];
    setGlobalVariableValue({
      key: 'slctdSectorTitle',
      value: sectorData.SectorTitle,
    });
    console.log(
      'sector title =' + sectorData.id + '=> ' + Variables.slctdSectorTitle
    );
    //Variables.slctdSectorTitle = sectorData.SectorTitle;
    setGlobalVariableValue({
      key: 'slctdTitle',
      value: sectionObj.SectionTitle,
    });
    //Variables.slctdTitle = sectionObj.SectionTitle;
    setGlobalVariableValue({
      key: 'indoorsList',
      value: sectionObj.SectionItems,
    });
    //Variables.indoorsList = sectionObj.SectionItems;
  };

  const _trySharingPhoto = async () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    //let img1 = Images.IMG2084;

    const fileUri = ExpoFileSys.cacheDirectory + 'tmp.jpg';
    const imgUrl = 'https://i.imgur.com/B8rw1pu.jpeg'; //'https://imgur.com/FDbumx3';

    var canShare = false;
    Sharing.isAvailableAsync().then(available => {
      if (available) {
        canShare = true;
        console.log('Sharing is available');
        ///////////////////////////////////////////////

        console.log('Now ==> Sharing');
        const options = {
          mimeType: 'image/jpeg',
          dialogTitle: 'Share the image',
          UTI: 'image/jpeg',
        };

        ExpoFileSys.downloadAsync(imgUrl, fileUri)
          .then(({ uri }) => {
            console.log(`Downloaded image to ${uri}`);
          })
          .catch(err => {
            console.log('Error downloading image');
            console.log(JSON.stringify(err));
          });

        // Sharing only allows one to share a file.
        Sharing.shareAsync(fileUri, options)
          .then(data => {
            console.log('Shared');
          })
          .catch(err => {
            console.log('Error sharing image');
            console.log(JSON.stringify(err));
          });

        ////////////////////////////////////////////////
      } else {
        canShare = false;
        console.log('Sharing is NOT available');
      }
    });

    if (canShare == true) {
      console.log('Now ==> Sharing');
      const options = {
        mimeType: 'image/jpeg',
        dialogTitle: 'Share the image',
        UTI: 'image/jpeg',
      };

      ExpoFileSys.downloadAsync(imgUrl, fileUri)
        .then(({ uri }) => {
          console.log(`Downloaded image to ${uri}`);
        })
        .catch(err => {
          console.log('Error downloading image');
          console.log(JSON.stringify(err));
        });

      // Sharing only allows one to share a file.
      Sharing.shareAsync(fileUri, options)
        .then(data => {
          console.log('Shared');
        })
        .catch(err => {
          console.log('Error sharing image');
          console.log(JSON.stringify(err));
        });
    }
  };

  const _ScrollFillWholeCardView = (
    Variables,
    setGlobalVariableValue,
    sectorId,
    sectionId
  ) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return (
      <>
        {/* View Card */}
        <View
          style={{
            borderBottomWidth: 1,
            borderColor: theme.colors.text.strong,
            borderLeftWidth: 1,
            borderRadius: 8,
            borderRightWidth: 1,
            borderTopWidth: 1,
            height: 130,
            marginLeft: 5,
            marginRight: 5,
            marginTop: 8,
            width: 150,
          }}
        >
          <Touchable
            onPress={() => {
              try {
                setSectSectionData(setGlobalVariableValue, sectorId, sectionId);
                setScrollCurrIdx(sectionId);
                setGlobalVariableValue({
                  key: 'ShowSwiper',
                  value: !Constants['ShowSwiper'],
                });
                setListChanged(true);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Utils.CustomCodeErrorBoundary>
              <FillScrollCardSectionJSX.MakeJsx
                theme={theme}
                itemId={sectionId}
                scrollCurrIdx={scrollCurrIdx}
              />
            </Utils.CustomCodeErrorBoundary>
          </Touchable>
        </View>
      </>
    );
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setSectSectionData(
        Variables,
        setGlobalVariableValue,
        Constants['slctdSectorId'],
        1
      );
      setListChanged(true);
      setScrollCurrIdx(1);
      setGlobalVariableValue({
        key: 'ShowSwiper',
        value: true,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes['App Fire'].myLightBlue, opacity: 1 },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'stretch'}
        source={imageSource(Images['IMG0001'])}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', marginTop: 45, width: '100%' },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AllViewsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes['Brand Fire'].Strong_Inverse,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                },
                dimensions.width
              )}
            >
              {Constants['slctdSectorTitle']}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('VillaDetailsAndBookingScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'MaterialCommunityIcons/calendar-clock'}
                />
              </View>
            </Touchable>
          </View>
        </View>

        <ScrollView
          bounces={true}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              backgroundColor: palettes['App Fire'].TripItBlue,
              borderBottomWidth: 1,
              borderColor: palettes['App Fire']['BG Gray'],
              borderLeftWidth: 1,
              borderRadius: 3,
              borderRightWidth: 1,
              borderTopWidth: 1,
              flexDirection: 'row',
              marginBottom: 9,
              marginTop: 5,
              maxHeight: 145,
              paddingBottom: 2,
              paddingTop: 2,
            },
            dimensions.width
          )}
        >
          <Utils.CustomCodeErrorBoundary>
            <ScrollFillWholeCardView.FillJsx
              theme={props.theme}
              scrollCurrIdx={scrollCurrIdx}
              setScrollCurrIdx={setScrollCurrIdx}
              setListChanged={setListChanged}
              sectorId={Variables.slctdSectorId}
            />
          </Utils.CustomCodeErrorBoundary>
        </ScrollView>
        {/* View section title */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              height: 35,
              marginBottom: 20,
              marginTop: -25,
              position: 'absolute',
              width: '100%',
              zIndex: 999,
            },
            dimensions.width
          )}
        >
          <Utils.CustomCodeErrorBoundary>
            <SetSectionTitle.SectionTitle
              theme={props.theme}
              slctdTtr={Variables.slctdTitle}
            />
          </Utils.CustomCodeErrorBoundary>
        </View>
        {/* View SwiperCode1 */}
        <>
          {!Constants['ShowSwiper'] ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%', zIndex: 99 },
                dimensions.width
              )}
            >
              <Utils.CustomCodeErrorBoundary>
                <DrbSwiper.Swipe
                  theme={props.theme}
                  myData={Variables.indoorsList}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  img2Zoom={img2Zoom}
                  setImg2Zoom={setImg2Zoom}
                  listChanged={listChanged}
                  setListChanged={setListChanged}
                />
              </Utils.CustomCodeErrorBoundary>
            </View>
          )}
        </>
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            { color: theme.colors.text.strong },
            dimensions.width
          )}
        >
          {false}
        </Text>
        {/* View SwiperCode2 */}
        <>
          {Constants['ShowSwiper'] ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%', zIndex: 99 },
                dimensions.width
              )}
            >
              <Utils.CustomCodeErrorBoundary>
                <DrbSwiper.Swipe
                  theme={props.theme}
                  myData={Variables.indoorsList}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  img2Zoom={img2Zoom}
                  setImg2Zoom={setImg2Zoom}
                  listChanged={listChanged}
                  setListChanged={setListChanged}
                />
              </Utils.CustomCodeErrorBoundary>
            </View>
          )}
        </>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(SwiperSectorExplorerScreen);
