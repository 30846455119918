import Images from '../config/Images';

export const Dico = {
  IMG4438: Images.IMG4438,
  IMG0901: Images.IMG0901,
  IMG0898: Images.IMG0898,
  IMG5105: Images.IMG5105,
  IMG4249: Images.IMG4249,
  IMG5118: Images.IMG5118,
  IMG1028: Images.IMG1028,
  IMG1029: Images.IMG1029,
  IMG3427:
    'https://res.cloudinary.com/dcmydoriy/image/upload/v1675908220/ViVi/IMG_3427_hv92hn.jpg',
  //Images.IMG3427,
  IMG0795: Images.IMG0795,
  IMG3401: Images.IMG3401,
  IMG1038: Images.IMG1038,
  IMG4224: Images.IMG4224,
  IMG1084: Images.IMG1084,
  IMG0901: Images.IMG0901,
  IMG1085: Images.IMG1085,
  IMG2189: Images.IMG2189,
  IMG5072: Images.IMG5072,
  IMG5074: Images.IMG5074,
  IMG4246: Images.IMG4246,
  IMG2920: Images.IMG2920,
  IMG0733: Images.IMG0733,
  IMG2153: Images.IMG2153,
  IMG1078: Images.IMG1078,
  IMG1056: Images.IMG1056,
  IMG1056B: Images.IMG1056B,
  IMG2084:
    'https://res.cloudinary.com/dcmydoriy/image/upload/v1675906567/ViVi/IMG_2084_tzptxh.jpg',
  //Images.IMG2084,
  IMG2225: Images.IMG2225,
  IMG3418: Images.IMG3418,
  IMG3415: Images.IMG3415,
  IMG1188: Images.IMG1188,
  IMG2811: Images.IMG2811,
  IMG1199: Images.IMG1199,
};
