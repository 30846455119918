import palettes from '../themes/palettes';
// This import is required if you are defining react components in this module.
import React from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { Text, Image, View } from 'react-native';

import * as GlobalVariableContext from '../config/GlobalVariableContext';

import * as FillScrollCardSectionJSX from '../custom-files/FillScrollCardSectionJSX.js';
import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct.js';

import * as Utils from '../utils';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <ScrollFillWholeCardView.MyExampleComponent />
export const FillJsx = props => {
  const { theme } = props;
  const { scrollCurrIdx } = props;
  const { setScrollCurrIdx } = props;
  const { setListChanged } = props;
  const { sectorId } = props;

  const Constants = GlobalVariableContext.useValues();

  const setGlobalVariable = GlobalVariableContext.useSetValue();

  //console.log('ScrollFillWholeCardView::sectorId ==> '+sectorId);
  let _sectObj = GetSwiperSectorStruct.SectorData(sectorId); //1
  let _sectorData = _sectObj.sectorData;
  //console.log("ScrollFillWholeCardView::Sector Title => "+_sectorData.SectorTitle);

  //setGlobalVariable({key:"slctdSectorTitle", value:_sectorData.SectorTitle });

  const setSectSectionData = (setGlobalVariable, sectorId, sectionId) => {
    let sectObj = GetSwiperSectorStruct.SectorData(sectorId); //1
    let sectorData = sectObj.sectorData;
    setGlobalVariable({
      key: 'slctdSectorTitle',
      value: sectorData.SectorTitle,
    });
    let sectionObj = sectorData.Sections[sectionId - 1];
    let slctdTitle = sectionObj.SectionTitle;
    setGlobalVariable({ key: 'slctdTitle', value: slctdTitle });
    let sectionsList = sectionObj.SectionItems;
    setGlobalVariable({ key: 'indoorsList', value: sectionsList });
  };

  // now generate all fragments of the sector's sections :
  let Sections = GetSwiperSectorStruct.SectorData(sectorId).sectorData.Sections; //[{"id":1},{"id":2}];
  return (
    <>
      {Sections.map(section => (
        <React.Fragment key={section.id}>
          <View
            style={{
              borderBottomWidth: 1,
              borderColor: theme.colors.text.strong,
              borderLeftWidth: 1,
              borderRadius: 8,
              borderRightWidth: 1,
              borderTopWidth: 1,
              height: 130,
              marginLeft: 5,
              marginRight: 5,
              marginTop: 8,
              width: 150,
            }}
          >
            <Touchable
              onPress={() => {
                try {
                  console.log('WholeCardView::Try ==secion.id==>' + section.id);
                  setSectSectionData(setGlobalVariable, sectorId, section.id);
                  setScrollCurrIdx(section.id);
                  setGlobalVariable({
                    key: 'ShowSwiper',
                    value: !Constants['ShowSwiper'],
                  });
                  setListChanged(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Utils.CustomCodeErrorBoundary>
                <FillScrollCardSectionJSX.MakeJsx
                  theme={theme}
                  itemId={section.id}
                  itemText={section.SectionScrollTitle}
                  imgUri={section.SectionImg}
                  scrollCurrIdx={scrollCurrIdx}
                />
              </Utils.CustomCodeErrorBoundary>
            </Touchable>
          </View>
        </React.Fragment>
      ))}
    </>
  );
};
