import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  AuthToken:
    'Bearer eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.-w5iTeOwVJ4AhjkkMZeF0OIhhS8dD0mPMKhX_uc2xmgkmPxpK971-Op_ffSqoiIRnFSCOqZdStGxSuWUQizoMZXvHU_-Qbgj.wmRitWsqfjlt7K9ANJNz1A.Asle9B5V0p-qyQ8aW95SyFRDyxi4DZz14VNs18wxEkiV0-XMohlE2th_YEFR2jhcWP8MY5C_2Wj24KLeAMeBBmQVeYyYu6jxV7a6VDuNWRsfAyAgKRhgyqMr8IfVUZuMf2auArrcI4Eu07CW7lcZW0yQNlegoRxyQf8pkxgMduo.ytzxzyGygWhU4qJdt4GOrXPkur_ueBsAM7jNmtXqr6Q',
  dpPrice_id: 'price_1NX1LhKTHVisr5NGrezQb1ra',
  LANG: 'EN',
  LoginIdent: '',
  LoginMdp: '',
  OpenAiKy: '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',
  rdPrice_id: 'price_1NVZD0KTHVisr5NGkHkExWp2',
  tPrice_id: 'price_1N1Vx0KTHVisr5NGpbfcjfwG',
  __env__: 'Development',
};
export const AppVariables = {
  apiResaBokdData: [],
  BackFromResaCalendar: false,
  currSectionData: '',
  currSectionItem: '',
  dateTimeStamp: '',
  glblDuePrice: 0,
  glblEndOfBooking: '',
  glblFalse: false,
  glblNightsBooked: 0,
  glblPersonsBooked: 0,
  glblRestDuePrice: 0,
  glblStartOfBooking: '',
  glblTrue: true,
  HiSeasonEnd: '',
  HiSeasonStart: '',
  Img2Zoom: '',
  indoorsList: [
    {
      id: 1,
      img: './images/img1.png',
      descr:
        'Hello World this is me  - L1\nHello World this is me  - L2\rHello World this is me  - L3',
      title: 'my title1',
    },
    {
      id: 2,
      img: './images/img2.png',
      descr: 'Life should be fun for every one  - 2',
      title: 'my title2',
    },
    {
      id: 3,
      img: './images/img3.png',
      descr: 'that what can be will be : 3',
      title: 'my title3',
    },
  ],
  LABELS: {
    greetings: { EN: 'Hello', FR: 'Bonjour' },
    bestVacations: {
      EN: 'Your ever best vacation is in Croatia',
      FR: 'Votre meilleur vacance est en Croatie',
    },
  },
  OpenAiKey: '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',
  paymentSessId: '',
  paymentUrl: '',
  ResaNonDispoDates: '',
  SectionsImgs: '',
  sectorData: {},
  ShowSwiper: false,
  slctdSectionId: 0,
  slctdSectorId: 1,
  slctdSectorTitle: '...',
  slctdTitle: '...',
  usrBookingDates: '',
  VacSeasonEnd: '',
  VacSeasonId: 1,
  VacSeasonStart: '',
  VacYear: '',
  VillaId: 0,
  VillaImgUrl: '',
  VillasData: '',
  VillaVals: {
    id: 1,
    beds: 8,
    city: 'Slatine - Ciovo Island',
    name: 'Oleandre Palm Villa',
    bedrooms: 3,
    latitude: 43.51012,
    min_stay: '6 nights',
    bathrooms: 2,
    image_url:
      'https://res.cloudinary.com/dcmydoriy/image/upload/v1675945284/ViVi/IMG_0584_sxndqg.jpg',
    longitude: 16.31032,
    maxGuests: 8,
    host_since: 2011,
    description:
      'A 1990 French Riviera Modern Style home; Situated on the costal line of Slatine Ciovo Island, at only 30 meters from the beach; Nested and surrounded by nature with palms and exotic trees, Includes a great view to the beach and the Kastelas on the opposing  horizon.',
    cleaning_fee: '75€ for 3 hours',
    weekly_price: 2500,
    booking_price: 650,
    nightly_price: 360,
    number_of_reviews: 1,
    cancellation_policy: 'strict',
    monthly_rental_price: 9750,
    review_scores_rating: 90,
  },
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
