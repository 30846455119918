import React from 'react';
import {
  DeckSwiper,
  DeckSwiperCard,
  Icon,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, Modal, Text, View } from 'react-native';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as FillDeckSwiperCards from '../custom-files/FillDeckSwiperCards';
import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct';
import * as goShareActivePhoto from '../custom-files/goShareActivePhoto';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const OneSectionCardsSwipingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [showModal, setShowModal] = React.useState(false);
  const [swipedInIdx, setSwipedInIdx] = React.useState(1);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const goZoomPhoto = (Variables, setGlobalVariableValue) => {
    // go to zoom the current displaied swipedInImg
    let imgUrl = Variables.currSectionItem.img;
    console.log('currSectionItem.imgUrl ==> ' + imgUrl);
    setGlobalVariableValue({ key: 'Img2Zoom', value: imgUrl });
    setShowModal(true);
  };

  const DeckJustSwipedInIdx = (Variables, setGlobalVariableValue, inIdx) => {
    // increment the swipedInIdx of the DeckCardsSwiper
    let idx = inIdx + 1;
    let mxi = Variables.currSectionData.SectionItems.length; // 2;
    if (idx > mxi) idx = 1;
    setSwipedInIdx(idx);
    console.log('swipedInIdx ==> ' + idx);
    //console.log(" SectionImgsLength ==> "+ mxi)
    let currItem = Variables.currSectionData.SectionItems[idx - 1]; // Arr 0...(n-1)
    setGlobalVariableValue({ key: 'currSectionItem', value: currItem });
  };

  const prepOneSectionData = (Variables, setGlobalVariableValue) => {
    // prepare the data of the selected section within the selected sctor;
    // using slctdSectorId & slctdSectionId

    const currImage = imgStr => {
      const theImg = ImageDico.Dico[imgStr];
      if (typeof theImg === 'string') {
        console.log('imgUri =CloudImg=> ' + theImg);
        return { uri: theImg };
      } else return theImg;
    };

    setSwipedInIdx(1);
    setShowModal(false);

    let sctnData = Variables.currSectionData;

    if (sctnData.SectionItems == undefined) {
      let sectId = Variables.slctdSectorId;
      let sectObj = GetSwiperSectorStruct.SectorData(sectId); //1
      let sectorData = sectObj.sectorData;
      let allSectionsData = sectorData.Sections;
      let sctnId = Variables.slctdSectionId;
      sctnId = sctnId ? sctnId : 1;
      sctnData = allSectionsData[sctnId - 1];
    }

    /* example of one section data
           { "id" : 1,
             "SectionScrollTitle" : "5 stars comfort",
             "SectionTitle" : "Enjoy & Relaxe",              
             "SectionImg" : "IMG2084",
             "SectionItems" : [                            
               {"id":1,"img":"IMG2084","descr":"The Sea at arms reach while you sip your morning coffe or enjoying your evening drink.","title":"Outdoors living"},
               //{"id":2,"img":"IMG1056B","descr":"Meridians corner just thought for your late afternoons. Enjoying a special book of yours, Listening to your prefered music or taking a little end of day nap.\rThe setting is your\'s, so relaxe and enjoy.","title":"Meridians for dreamy moments"},
               //{"id":3,"img":"IMG1038","descr":"A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r","title":"Stairway to Relaxation"}                  
             ],
           },
       */

    let sctnScrlTtl = sctnData.SectionScrollTitle;
    let sctnTtl = sctnData.SectionTitle;
    let sctnTitle = sctnScrlTtl + ' ' + sctnTtl;
    //console.log("sctnTitle ==> "+sctnTitle);
    let oneSctnItems = sctnData.SectionItems; //currSectionData.SectionItems
    setGlobalVariableValue({ key: 'currSectionData', value: sctnData });

    let currItem = oneSctnItems[0]; // Arr 0...(n-1)
    setGlobalVariableValue({ key: 'currSectionItem', value: currItem });

    //console.log("oneSctnItems ==> "+JSON.stringify(oneSctnItems) )
  };

  const goSharePhoto = Variables => {
    // go to share the current displaied swipedInImg to outside apps
    let imgUrl = Variables.currSectionItem.img;
    console.log('currSectionItem.imgUrl ==> ' + imgUrl);
    goShareActivePhoto.ShareIt(imgUrl);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      prepOneSectionData(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          alignContent: 'center',
          backgroundColor: palettes['App Fire'].myLightBlue,
          flex: 1,
        },
        dimensions.width
      )}
    >
      <ImageBackground
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        backfaceVisibility={'visible'}
        resizeMode={'stretch'}
        source={imageSource(Images['IMG0001'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { alignContent: 'flex-start', height: null, width: null }
          ),
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          {...GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].style,
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes['Brand Fire'].Strong_Inverse,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  letterSpacing: 2,
                },
                dimensions.width
              )}
            >
              {Constants['currSectionData']?.SectionTitle}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('VillaDetailsAndBookingScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'MaterialCommunityIcons/calendar-clock'}
                />
              </View>
            </Touchable>
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'center',
              marginTop: 11,
              maxWidth: 542,
              paddingLeft: 15,
              paddingRight: 15,
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                alignSelf: 'center',
                color: theme.colors.text.strong,
                fontFamily: 'Poppins_500Medium',
                fontSize: 16,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {Constants['currSectionData']?.SectionScrollTitle}
          </Text>
          <Utils.CustomCodeErrorBoundary>
            <FillDeckSwiperCards.FillJsx
              theme={props.theme}
              swipedInIdx={swipedInIdx}
              setSwipedInIdx={setSwipedInIdx}
            />
          </Utils.CustomCodeErrorBoundary>
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                alignSelf: 'center',
                color: palettes['App Fire'].myDarkBlue,
                fontFamily: 'Poppins_200ExtraLight',
                fontSize: 16,
                letterSpacing: 2,
                marginTop: 5,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'\nswipe cards to view all'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              { alignSelf: 'center', marginTop: 5 },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginTop: 4,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    goZoomPhoto(Variables, setGlobalVariableValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon name={'MaterialIcons/zoom-in'} size={28} />
              </Touchable>
              <Spacer bottom={8} left={8} right={8} top={8} />
              <Touchable
                onPress={() => {
                  try {
                    goSharePhoto(Variables);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon size={24} name={'Feather/share'} />
              </Touchable>
            </View>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { marginTop: 15 }
                ),
                dimensions.width
              )}
            >
              {Constants['currSectionItem']?.title}
            </Text>
            <TextInput
              autoCorrect={true}
              changeTextDelay={500}
              multiline={true}
              onChangeText={newTextAreaValue => {
                try {
                  setGlobalVariableValue({
                    key: 'currSectionItem',
                    value: newTextAreaValue,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
              }
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
              defaultValue={Constants['currSectionItem']?.descr}
              editable={false}
              numberOfLines={5}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_400Regular',
                    marginTop: 4,
                    textAlign: 'justify',
                    whiteSpace: 'pre-line',
                  }
                ),
                dimensions.width
              )}
            />
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(OneSectionCardsSwipingScreen);
