import React from 'react';
import {
  Button,
  Icon,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Image, ImageBackground, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SignInScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes['App Fire'].TripItBlue },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={imageSource(Images['IMG0001'])}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* FullNavigationFrame */}
        <View
          {...GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].style,
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes['Brand Fire'].Strong_Inverse,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  letterSpacing: 2,
                },
                dimensions.width
              )}
            >
              {Constants['currSectionData']?.SectionTitle}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          ></View>
        </View>

        <KeyboardAwareScrollView
          enableAutomaticScroll={false}
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
          viewIsInsideTabBar={false}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              justifyContent: 'center',
              marginTop: -44,
              paddingBottom: 48,
              paddingLeft: 48,
              paddingRight: 48,
              paddingTop: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'contain'}
              source={imageSource(Images['AppLogo'])}
              style={StyleSheet.applyWidth(
                {
                  borderBottomLeftRadius: 22,
                  borderTopRightRadius: 22,
                  height: 47,
                  width: 47,
                },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 28,
                  marginLeft: 10,
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {'VillaViews'}
            </Text>
          </View>
          <Spacer bottom={8} left={8} right={8} top={18} />
          <View>
            <Spacer left={8} right={8} bottom={4} top={29} />
          </View>
          <Spacer left={8} right={8} bottom={24} top={24} />
          <View>
            {/* Email */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Email'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: theme.colors.text.strong,
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors.text.strong}
                  name={'MaterialIcons/alternate-email'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  {/* Text Input email */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputEmailValue => {
                      const textInputValue = newTextInputEmailValue;
                      try {
                        setTextInputValue(textInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    keyboardType={'email-address'}
                    placeholder={'JaneDoh@email.com'}
                    placeholderTextColor={palettes['App Fire'].myStone}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes['Brand Fire'].Strong_Inverse,
                        borderRadius: 3,
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 18,
                        paddingLeft: 4,
                      },
                      dimensions.width
                    )}
                    value={textInputValue}
                  />
                </View>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* Password */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
              >
                {'Password'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: theme.colors.text.strong,
                    flexDirection: 'row',
                    marginTop: 8,
                    paddingBottom: 12,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors.text.strong}
                  name={'MaterialIcons/lock-outline'}
                  size={20}
                  style={StyleSheet.applyWidth(
                    { height: 20, width: 20 },
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 4 },
                    dimensions.width
                  )}
                >
                  {/* Text Input pwd */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInputPwdValue => {
                      const textInputValue = newTextInputPwdValue;
                      try {
                        setTextInputValue(textInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    keyboardType={'default'}
                    placeholder={'MySecretPassword'}
                    placeholderTextColor={palettes['App Fire'].myStone}
                    secureTextEntry={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes['Brand Fire'].Strong_Inverse,
                        borderRadius: 3,
                        color: palettes['Brand Fire'].Surface,
                        fontFamily: 'System',
                        fontSize: 18,
                        fontWeight: '400',
                        paddingLeft: 4,
                      },
                      dimensions.width
                    )}
                    value={textInputValue}
                  />
                </View>
              </View>
            </View>
          </View>
          <Spacer left={8} right={8} bottom={32} top={32} />
          <View>
            {/* Button Solid */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('MainTabs', { screen: 'HomeScreen' });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.text.strong,
                  borderBottomWidth: 2,
                  borderColor: palettes['Brand Fire'].Medium_Inverse,
                  borderLeftWidth: 1,
                  borderRadius: 44,
                  borderRightWidth: 3,
                  borderTopWidth: 1,
                  color: palettes['Brand Fire'].Surface,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 16,
                  paddingBottom: 16,
                  paddingTop: 16,
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={'Sign In'}
            />
            <Spacer left={8} right={8} bottom={12} top={12} />
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'center' },
                dimensions.width
              )}
            >
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    navigation.navigate('AuthStack', {
                      screen: 'SignUpScreen',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={"Don't have an account? Sign Up"}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(SignInScreen);
