import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import * as GlobalVariables from './config/GlobalVariableContext';
import AllViewsScreen from './screens/AllViewsScreen';
import BookingDatesScreen from './screens/BookingDatesScreen';
import BookingEstimatesScreen from './screens/BookingEstimatesScreen';
import CompleteAccountFormScreen from './screens/CompleteAccountFormScreen';
import HomeScreen from './screens/HomeScreen';
import OneSectionCardsSwipingScreen from './screens/OneSectionCardsSwipingScreen';
import PaymentCardInfoScreen from './screens/PaymentCardInfoScreen';
import ProfileScreen from './screens/ProfileScreen';
import ResaCalendarScreen from './screens/ResaCalendarScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import SectionsMasonryListScreen from './screens/SectionsMasonryListScreen';
import SignInScreen from './screens/SignInScreen';
import SignUpScreen from './screens/SignUpScreen';
import SwiperSectorExplorerScreen from './screens/SwiperSectorExplorerScreen';
import VillaDetailsAndBookingScreen from './screens/VillaDetailsAndBookingScreen';
import VillaViewsAnimScreen from './screens/VillaViewsAnimScreen';
import VillaViewsScreen from './screens/VillaViewsScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import useIsOnline from './utils/useIsOnline';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function AuthStack() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  const isOnline = useIsOnline();

  return (
    <Stack.Navigator
      initialRouteName="VillaViewsAnimScreen"
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerMode: 'none',
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="VillaViewsScreen"
        component={VillaViewsScreen}
        options={({ navigation }) => ({
          title: 'VillaViews',
        })}
      />
      <Stack.Screen
        name="SignUpScreen"
        component={SignUpScreen}
        options={({ navigation }) => ({
          title: 'Sign Up',
        })}
      />
      <Stack.Screen
        name="SignInScreen"
        component={SignInScreen}
        options={({ navigation }) => ({
          title: 'Sign In',
        })}
      />
      <Stack.Screen
        name="VillaViewsAnimScreen"
        component={VillaViewsAnimScreen}
        options={({ navigation }) => ({
          title: 'VillaViewsAnim',
        })}
      />
    </Stack.Navigator>
  );
}

function MainTabs() {
  const theme = useTheme();

  const tabBarOrDrawerIcons = {
    HomeScreen: 'Feather/home',
    ProfileScreen: 'Feather/user',
  };

  return (
    <Tab.Navigator
      screenOptions={({ navigation }) => ({
        headerShown: false,
        tabBarActiveBackgroundColor: palettes['App Fire'].MyBlack,
        tabBarInactiveBackgroundColor: palettes['App Fire'].MyBlack,
        tabBarShowLabel: false,
        tabBarStyle: { borderTopColor: 'transparent' },
      })}
    >
      <Tab.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Feather/home"
              size={25}
              color={
                focused ? palettes['App Fire'].Golden : theme.colors.text.light
              }
            />
          ),
          tabBarLabel: 'Home',
          title: 'Home',
        })}
      />
      <Tab.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Feather/user"
              size={25}
              color={
                focused ? palettes['App Fire'].Golden : theme.colors.text.light
              }
            />
          ),
          tabBarLabel: 'Profile',
          title: 'Profile',
        })}
      />
    </Tab.Navigator>
  );
}

function ScreenStack() {
  const theme = useTheme();

  /* Navigator has no children, add a child screen or navigator to have it rendered here */
}

export default function RootAppNavigator() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="AuthStack"
        screenOptions={({ navigation }) => ({
          animationEnabled: false,
          cardOverlayEnabled: false,
          cardStyle: { flex: 1 },
          gestureEnabled: false,
          gestureVelocityImpact: 0.7,
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerMode: 'none',
          headerShown: false,
          tabBarShowLabel: true,
        })}
      >
        <Stack.Screen
          name="AllViewsScreen"
          component={AllViewsScreen}
          options={({ navigation }) => ({
            title: 'AllViews',
          })}
        />
        <Stack.Screen
          name="VillaDetailsAndBookingScreen"
          component={VillaDetailsAndBookingScreen}
          options={({ navigation }) => ({
            title: 'VillaDetailsAndBooking',
          })}
        />
        <Stack.Screen
          name="BookingDatesScreen"
          component={BookingDatesScreen}
          options={({ navigation }) => ({
            title: 'BookingDates',
          })}
        />
        <Stack.Screen
          name="ResaCalendarScreen"
          component={ResaCalendarScreen}
          options={({ navigation }) => ({
            title: 'ResaCalendar',
          })}
        />
        <Stack.Screen
          name="BookingEstimatesScreen"
          component={BookingEstimatesScreen}
          options={({ navigation }) => ({
            title: 'BookingEstimates',
          })}
        />
        <Stack.Screen
          name="PaymentCardInfoScreen"
          component={PaymentCardInfoScreen}
          options={({ navigation }) => ({
            title: 'PaymentCardInfo',
          })}
        />
        <Stack.Screen
          name="CompleteAccountFormScreen"
          component={CompleteAccountFormScreen}
          options={({ navigation }) => ({
            title: 'CompleteAccountForm',
          })}
        />
        <Stack.Screen
          name="ResetPasswordScreen"
          component={ResetPasswordScreen}
          options={({ navigation }) => ({
            title: 'ResetPassword',
          })}
        />
        <Stack.Screen
          name="SwiperSectorExplorerScreen"
          component={SwiperSectorExplorerScreen}
          options={({ navigation }) => ({
            title: 'SwiperSectorExplorer',
          })}
        />
        <Stack.Screen
          name="SectionsMasonryListScreen"
          component={SectionsMasonryListScreen}
          options={({ navigation }) => ({
            title: 'SectionsMasonryList',
          })}
        />
        <Stack.Screen
          name="OneSectionCardsSwipingScreen"
          component={OneSectionCardsSwipingScreen}
          options={({ navigation }) => ({
            animationEnabled: false,
            cardOverlayEnabled: false,
            gestureEnabled: false,
            title: 'OneSectionCardsSwiping',
          })}
        />
        <Stack.Screen
          name="AuthStack"
          component={AuthStack}
          options={({ navigation }) => ({
            title: 'AuthStack',
          })}
        />
        <Stack.Screen
          name="MainTabs"
          component={MainTabs}
          options={({ navigation }) => ({
            title: 'MainTabs',
          })}
        />
        <Stack.Screen
          name="ScreenStack"
          component={ScreenStack}
          options={({ navigation }) => ({
            title: 'ScreenStack',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
