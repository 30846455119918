// This import is required if you are defining react components in this module.
import React from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { Text } from 'react-native';
import { FAB } from '@draftbit/ui';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <Wait4AssetsLoadBeforeWelcome.WaitSecs />
export const WaitSecs = props => {
  const { navigation } = props;
  const { wSecs } = props;
  const { setShowImg1 } = props;
  const { setShowImg2 } = props;
  const { setShowImg3 } = props;
  const { setShowImg4 } = props;
  const { navFired } = props;
  const { setNavFired } = props;
  //alert("waitSecs ==> "+wSecs)
  /*
    setTimeout ( () => {
 
        //navigation.navigate("WelcomeScreen");
        navigation.navigate('AuthStack', { screen: 'WelcomeScreen', });

    }, wSecs );
    */
  let var1, var2, var3, var4, var5;
  if (!navFired) {
    setNavFired(true);
    var1 = setTimeout(() => {
      setShowImg1(false);
      setShowImg2(true);
      setShowImg3(false);
      setShowImg4(true);
      var2 = setTimeout(() => {
        setShowImg1(false);
        setShowImg2(true);
        setShowImg3(true);
        setShowImg4(false);
        var3 = setTimeout(() => {
          setShowImg1(true);
          setShowImg2(true);
          setShowImg3(true);
          setShowImg4(true);

          var4 = setTimeout(() => {
            clearTimeout(var1);
            clearTimeout(var2);
            clearTimeout(var3);
            clearTimeout(var4);

            setShowImg1(true);
            setShowImg2(true);
            setShowImg3(true);
            setShowImg4(true);

            var5 = setTimeout(() => {
              console.log('Just fired goto::WelcomeScreen');
              clearTimeout(var5);
              navigation.navigate('AuthStack', { screen: 'WelcomeScreen' });
            }, 1353);
          }, 1353);
        }, 1135);
      }, 937);
    }, 753);
  }
};
