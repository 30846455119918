import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({
    'Activity Indicator': { style: { height: 36, width: 36 }, props: {} },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    Text: { style: { color: theme.colors.text.strong }, props: {} },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'Web View': { style: { flex: 1 }, props: {} } });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({
    'Image Background': { style: { height: '100%', width: '100%' }, props: {} },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Area': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
  });

export const DeckSwiperStyles = theme =>
  StyleSheet.create({ 'Deck Swiper': { style: { width: '100%' }, props: {} } });

export const DeckSwiperCardStyles = theme =>
  StyleSheet.create({
    'Deck Swiper Card': {
      style: {
        alignItems: 'center',
        borderWidth: 2,
        flex: 1,
        justifyContent: 'center',
        padding: 20,
      },
      props: {},
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    fullNavigationFrame: {
      style: { flexDirection: 'row', marginTop: 45, width: '100%' },
      props: {},
    },
    view1: { style: { flex: 1, justifyContent: 'space-between' }, props: {} },
    view2: {
      style: {
        height: 150,
        marginBottom: 83,
        paddingBottom: 64,
        paddingLeft: 24,
        paddingRight: 24,
      },
      props: {},
    },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 8,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });
