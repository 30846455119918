export default {
  IMG5118: require('../assets/images/IMG5118.jpg'),
  IMG4249: require('../assets/images/IMG4249.jpg'),
  IMG1078: require('../assets/images/IMG1078.jpg'),
  IMG0898: require('../assets/images/IMG0898.jpg'),
  IMG0901: require('../assets/images/IMG0901.jpg'),
  Hide: require('../assets/images/Hide.png'),
  IMG4438: require('../assets/images/IMG4438.jpg'),
  JoelMottLaK153ghdigUnsplash: require('../assets/images/JoelMottLaK153ghdigUnsplash.jpg'),
  IMG3418: require('../assets/images/IMG3418.jpg'),
  IMG3415: require('../assets/images/IMG3415.jpg'),
  IMG1085: require('../assets/images/IMG1085.jpg'),
  IMG2189: require('../assets/images/IMG2189.jpg'),
  IMG5072: require('../assets/images/IMG5072.jpg'),
  IMG4246: require('../assets/images/IMG4246.jpg'),
  IMG5074: require('../assets/images/IMG5074.jpg'),
  IMG2084: require('../assets/images/IMG2084.jpg'),
  IMG0733: require('../assets/images/IMG0733.jpg'),
  IMG2920: require('../assets/images/IMG2920.jpg'),
  IMG2153: require('../assets/images/IMG2153.jpg'),
  IMG0355: require('../assets/images/IMG0355.jpg'),
  IMG0000: require('../assets/images/IMG0000.png'),
  IMG0001: require('../assets/images/IMG0001.png'),
  IMG2811: require('../assets/images/IMG2811.jpeg'),
  IMG1199: require('../assets/images/IMG1199.jpeg'),
  OleandrePalmVilla: require('../assets/images/OleandrePalmVilla.jpg'),
  AppLogo: require('../assets/images/AppLogo.jpg'),
};
