// This import is required if you are defining react components in this module.
import React from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.

//import { View } from 'react-native';
//import * as GlobalVariableContext from '../config/GlobalVariableContext';

import * as FillVScrollSectorCardJsx from '../custom-files/FillVScrollSectorCardJsx.js';
import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct.js';

import * as Utils from '../utils';

// You can use components exported from this file within a Custom Code component as
// <FillAllViewsSectorsCardsJsx.FillJsx />

export const FillJsx = props => {
  const { theme } = props;
  const { navigation } = props;

  const allSectors = GetSwiperSectorStruct.AllSectorsData();
  ////console.log("allSectorsData ==> ",JSON.stringify(allSectors));

  return (
    <>
      {allSectors?.map(sector => (
        <React.Fragment key={sector.id}>
          <Utils.CustomCodeErrorBoundary>
            <FillVScrollSectorCardJsx.FillJsx
              theme={theme}
              navigation={navigation}
              sectId={sector.id}
            />
          </Utils.CustomCodeErrorBoundary>
        </React.Fragment>
      ))}
    </>
  );
};
