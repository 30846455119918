import React from 'react';
import { Icon, ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { MasonryFlashList } from '@shopify/flash-list';
import { Alert, Image, ImageBackground, Text, View } from 'react-native';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct';
import * as ImageDico from '../custom-files/ImageDico';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SectionsMasonryListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const gotoSectionDetails = (Variables, setGlobalVariableValue, mlItemId) => {
    // prepare data for the selected section details

    console.log(' Selected Section Id ==> ' + mlItemId); // Variables.slctdSectionId);
    let sectId = Variables.slctdSectorId;
    let currSectionData = Variables.sectorData.Sections[mlItemId - 1];
    //console.log(" Selected Section currSectionData ==> "+JSON.stringify(currSectionData));
    setGlobalVariableValue({ key: 'slctdSectionId', value: mlItemId });
    setGlobalVariableValue({ key: 'currSectionData', value: currSectionData });
    props.navigation.navigate('OneSectionCardsSwipingScreen');
  };

  const setSectionsImgsData = (Variables, setGlobalVariableValue) => {
    // prep sections images struct for list display in the MasonaryList

    const currImage = imgStr => {
      const theImg = ImageDico.Dico[imgStr];
      if (typeof theImg === 'string') {
        console.log('imgUri =CloudImg=> ' + theImg);
        return { uri: theImg };
      } else return theImg;
    };

    let sectId = Variables.slctdSectorId;
    sectId = sectId ? sectId : 1;
    let sectObj = GetSwiperSectorStruct.SectorData(sectId); //1
    let sectorData = sectObj.sectorData;
    console.log('OneSectionData ===> ' + JSON.stringify(sectorData));
    let sectorTitle = sectorData.SectorScrollTitle; //
    setGlobalVariableValue({ key: 'slctdSectorTitle', value: sectorTitle });
    setGlobalVariableValue({ key: 'sectorData', value: sectorData });

    let sectionsData = sectorData.Sections; //array of sections data
    let sctnsImgs = [];
    for (var i = 0; i < sectionsData.length; i++) {
      let sctnObj = {};
      sctnObj.id = sectionsData[i].id;
      sctnObj.img = sectionsData[i].SectionImg;
      //'https://ii1.pepperfry.com/media/catalog/product/m/o/568x625/modern-chaise-lounger-in-grey-colour-by-dreamzz-furniture-modern-chaise-lounger-in-grey-colour-by-dr-tmnirx.jpg';
      //currImage(sectionsData[i].SectionImg);//Images.IMG2084;//
      sctnObj.txt = sectionsData[i].SectionScrollTitle;

      sctnsImgs[i] = sctnObj;
    }
    setGlobalVariableValue({ key: 'SectionsImgs', value: sctnsImgs });
    //console.log(" sectionsImgs ==> "+JSON.stringify(sctnsImgs));
    return sctnsImgs;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      const retSctnsImgs = setSectionsImgsData(
        Variables,
        setGlobalVariableValue
      );
      setGlobalVariableValue({
        key: 'SectionsImgs',
        value: retSctnsImgs,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes['App Fire'].myLightBlue },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['IMG0001'])}
        style={StyleSheet.applyWidth(
          GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          {...GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['fullNavigationFrame'].style,
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AllViewsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes['Brand Fire'].Strong_Inverse,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  marginTop: 9,
                },
                dimensions.width
              )}
            >
              {Constants['slctdSectorTitle']}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('VillaDetailsAndBookingScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'MaterialCommunityIcons/calendar-clock'}
                />
              </View>
            </Touchable>
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { height: '100%', marginTop: 15, paddingBottom: 25, width: '100%' },
            dimensions.width
          )}
        >
          <MasonryFlashList
            data={Constants['SectionsImgs']}
            keyExtractor={(masonryListData, index) => masonryListData?.id}
            listKey={'eSNXU9Pb'}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const masonryListData = item;
              return (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 11,
                      marginTop: 15,
                      paddingTop: 5,
                      width: '98%',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        console.log(masonryListData?.id);
                        setGlobalVariableValue({
                          key: 'slctdSectionId',
                          value: masonryListData?.id,
                        });
                        gotoSectionDetails(
                          Variables,
                          setGlobalVariableValue,
                          masonryListData?.id
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Image
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      resizeMode={'stretch'}
                      source={imageSource(`${masonryListData?.img}`)}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          {
                            borderBottomWidth: 5,
                            borderColor: palettes['Brand Fire'].Strong_Inverse,
                            borderRadius: 11,
                            borderRightWidth: 4,
                            borderWidth: 1,
                            height: 245,
                            marginBottom: 3,
                            width: 349,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            alignSelf: 'center',
                            color: theme.colors.text.strong,
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {masonryListData?.txt}
                    </Text>
                  </Touchable>
                </View>
              );
            }}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            estimatedItemSize={375}
          />
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(SectionsMasonryListScreen);
