import React, { useRef } from 'react';
//import Images from '../config/Images';
//import * as ImgDico  from '../custom-files/ImageDico.js';

import * as Sharing from 'expo-sharing';
import * as ExpoFileSys from 'expo-file-system';

async function trySharingPhoto(imageUrl) {
  console.log('the Foto ==url==> ' + imageUrl);
  const fileUri = ExpoFileSys.cacheDirectory + 'tmp.jpg';
  var imgUrl = imageUrl; //'https://apps-draftbit-com.s3.amazonaws.com/apps/0hbNFf4L/assets/cOA6iTRQq3i6GIM9_opas';
  var canShare = false;
  Sharing.isAvailableAsync().then(available => {
    if (available) {
      canShare = true;
      console.log('Sharing is available');
      ///////////////////////////////////////////////
      console.log('Now ==> Sharing');
      const options = {
        mimeType: 'image/jpeg',
        dialogTitle: 'Share the image',
        UTI: 'image/jpeg',
      };

      ExpoFileSys.downloadAsync(imgUrl, fileUri)
        .then(({ uri }) => {
          console.log(`Downloaded image to ${uri}`);
        })
        .catch(err => {
          console.log('Error downloading image');
          console.log(JSON.stringify(err));
        });

      // Sharing only allows one to share a file.
      Sharing.shareAsync(fileUri, options)
        .then(data => {
          console.log('Shared');
        })
        .catch(err => {
          console.log('Error sharing image');
          console.log(JSON.stringify(err));
        });
      ////////////////////////////////////////////////
    } else {
      canShare = false;
      console.log('Sharing is NOT available');
    }
  });
}
export const ShareIt = imgUrl => {
  //const { imgUrl } = props;

  const shareHandler = async () => {
    try {
      await trySharingPhoto(imgUrl);
    } catch (err) {
      console.error(err);
    }
  };
  shareHandler();
};
