import React from 'react';
import {
  Icon,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, StatusBar, Text, View } from 'react-native';
import * as CustomCode from '../CustomCode';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as FillAllViewsSectorsCardsJsx from '../custom-files/FillAllViewsSectorsCardsJsx';
import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AllViewsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [img2Zoom, setImg2Zoom] = React.useState('IMG2084');
  const [showZoomView, setShowZoomView] = React.useState(false);
  const setSlctdSectorParams = (setGlobalVariableValue, sectId) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    let sectObj = GetSwiperSectorStruct.SectorData(sectId); //1
    let sectorData = sectObj.sectorData;
    console.log(
      'setSlctdSectorParams::sectorData.SectorTitle ==> ',
      sectorData.SectorTitle
    );
    setGlobalVariableValue({ key: 'sectorData', value: sectorData });
    setGlobalVariableValue({
      key: 'slctdSectorTitle',
      value: sectorData.SectorTitle,
    });
    setGlobalVariableValue({ key: 'slctdSectorId', value: sectorData.id }); //
    setGlobalVariableValue({
      key: 'indoorsList',
      value: sectorData.Sections[sectId - 1].SectionItems,
    }); //
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }

      const entry = StatusBar.pushStackEntry?.({ barStyle: 'light-content' });
      return () => StatusBar.popStackEntry?.(entry);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes['App Fire'].myLightBlue },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'stretch'}
        source={imageSource(
          'https://res.cloudinary.com/dcmydoriy/image/upload/v1677592051/ViVi/vvFond_un0fbw.jpg'
        )}
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'flex-end',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', marginTop: 45, width: '100%' },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AuthStack');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes['App Fire']['Custom Color_15'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                },
                dimensions.width
              )}
            >
              {Constants['VillaVals']?.name}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('VillaDetailsAndBookingScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderRadius: 9,
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'MaterialCommunityIcons/calendar-clock'}
                />
              </View>
            </Touchable>
          </View>
        </View>

        <SimpleStyleScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={false}
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              borderStyle: 'dotted',
              justifyContent: 'space-evenly',
              marginBottom: 15,
              opacity: 1,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Utils.CustomCodeErrorBoundary>
            <FillAllViewsSectorsCardsJsx.FillJsx
              theme={theme}
              navigation={navigation}
            />
          </Utils.CustomCodeErrorBoundary>
        </SimpleStyleScrollView>

        <View>
          <Spacer bottom={8} left={8} right={8} top={18} />
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(AllViewsScreen);
