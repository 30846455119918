const readJsonVillaData = (Variables, dontUse, key) => {
  /*
console.log(CustomCode.VillaVals[key])
return CustomCode.VillaVals[key]
*/
  console.log(Variables.VillaVals[key]);
  /*
if ((key === "latitude") || (key === "longitude")) {
return parseFloat(Variables.VillaVals[key])
} else 
*/
  return Variables.VillaVals[key];
};

export default readJsonVillaData;
