import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const chkOutSessRestDueAmntPOST = async (
  Constants,
  { price_id },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:cv92JAsP/sessions`;
  const options = {
    body: JSON.stringify({
      success_url: 'https://www.example.com/success',
      cancel_url: 'https://www.example.com/cancel',
      line_items: [{ price: price_id, quantity: 1 }],
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization:
        'eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.Er4q9L88mEDHK1FzaDv9z-mqMf0gvtwjQINoZrbwE0AJ8rYTHBxrrFhw5yImqg8Ik4fTScwREZuCJPvkP2eWVxz0ClyewHsa.sSqk7K5mXvf6j_d5WuXr3A.vTJWsYE4s7_vo5f8ewCqh_k0spzzzII-TLIwGAQLpaxqbP3SnZvHJB-JjeM1erViTWovwwfMOn6Tuv0-kieMw8nZ9DG41uhq-0sP-LzpfLZ_G0qoLYKUlOSXQpGG8c_V71w7pCBCmf4j5q_iKmUDI2bM-7RRzJWEblNEtWn9R4E.NedNjKZq4Tyrxa6tErbUkFVoUo1OpAfPWA21utbaZC4',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useChkOutSessRestDueAmntPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoChkOutStripeChkOutSessRestDueAmntPOST', args],
    () => chkOutSessRestDueAmntPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoChkOutStripeChkOutSessRestDueAmntPOSTS',
        ]),
    }
  );
};

export const FetchChkOutSessRestDueAmntPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  price_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useChkOutSessRestDueAmntPOST(
    { price_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchChkOutSessRestDueAmnt: refetch,
  });
};

export const chkOutSessionPOST = async (
  Constants,
  { price_id },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:cv92JAsP/sessions`;
  const options = {
    body: JSON.stringify({
      success_url: 'https://www.example.com/success',
      cancel_url: 'https://www.example.com/cancel',
      line_items: [{ price: price_id, quantity: 1 }],
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: Constants['AuthToken'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useChkOutSessionPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoChkOutStripeChkOutSessionPOST', args],
    () => chkOutSessionPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoChkOutStripeChkOutSessionPOSTS']),
    }
  );
};

export const FetchChkOutSessionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  price_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useChkOutSessionPOST(
    { price_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchChkOutSession: refetch });
};

export const stripeSessionByIdGET = async (
  Constants,
  { id },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:cv92JAsP/sessions/${encodeQueryParam(
    id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useStripeSessionByIdGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoChkOutStripeStripeSessionByIdGET', args],
    () => stripeSessionByIdGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoChkOutStripeStripeSessionByIdGETS',
        ]),
    }
  );
};

export const FetchStripeSessionByIdGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStripeSessionByIdGET(
    { id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchStripeSessionById: refetch });
};
