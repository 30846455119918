import React from 'react';
import {
  Button,
  Icon,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ResetPasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes['App Fire'].MyBlack },
        dimensions.width
      )}
    >
      {/* Navigation Frame */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', marginTop: 5, width: '100%' },
          dimensions.width
        )}
      >
        {/* Left Side Frame */}
        <View>
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Icon Flex Frame */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexGrow: 0,
                  flexShrink: 0,
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* Icon  */}
              <Icon
                size={24}
                color={'theme.colors.estudio_primary'}
                name={'Ionicons/arrow-undo-sharp'}
                style={StyleSheet.applyWidth(
                  { backgroundColor: palettes['App Fire'].Golden },
                  dimensions.width
                )}
              />
            </View>
          </Touchable>
        </View>
        {/* Middle Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
            dimensions.width
          )}
        >
          {/* ScrTitle */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                color: palettes['Brand Fire'].Strong_Inverse,
                fontFamily: 'Poppins_500Medium',
                fontSize: 22,
                marginTop: 11,
              },
              dimensions.width
            )}
          >
            {'Reset'}
          </Text>
        </View>
        {/* Right Side Frame */}
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
            dimensions.width
          )}
        ></View>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            justifyContent: 'space-evenly',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        {/* Heading */}
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              color: palettes['App Fire'].Communial_White,
              fontFamily: 'Poppins_700Bold',
              fontSize: 35,
              marginBottom: 10,
            },
            dimensions.width
          )}
        >
          {'Reset your\nPassword'}
        </Text>
        {/* sub heading */}
        <Text
          accessible={true}
          selectable={false}
          style={StyleSheet.applyWidth(
            {
              color: palettes['App Fire']['Custom Color_9'],
              fontFamily: 'Poppins_400Regular',
              fontSize: 15,
              opacity: 0.7,
              paddingBottom: 10,
              textAlign: 'left',
            },
            dimensions.width
          )}
        >
          {'Please enter your new password'}
        </Text>
        {/* Password */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newPasswordValue => {
            try {
              setTextInputValue(newPasswordValue);
            } catch (err) {
              console.error(err);
            }
          }}
          webShowOutline={true}
          editable={true}
          placeholder={'Password'}
          placeholderTextColor={theme.colors.text.light}
          secureTextEntry={true}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes['App Fire']['Custom Color_3'],
              borderBottomWidth: 1,
              borderColor: theme.colors.border.brand,
              borderLeftWidth: 1,
              borderRadius: 24,
              borderRightWidth: 1,
              borderTopWidth: 1,
              fontFamily: 'Poppins_400Regular',
              fontSize: 15,
              height: 48,
              marginTop: 20,
              paddingBottom: 8,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 8,
            },
            dimensions.width
          )}
          value={textInputValue}
        />
        {/* Confirm Password */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newConfirmPasswordValue => {
            try {
              setTextInputValue(newConfirmPasswordValue);
            } catch (err) {
              console.error(err);
            }
          }}
          webShowOutline={true}
          editable={true}
          placeholder={'Confirm Password'}
          placeholderTextColor={theme.colors.text.light}
          secureTextEntry={true}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes['App Fire']['Custom Color_3'],
              borderBottomWidth: 1,
              borderColor: theme.colors.border.brand,
              borderLeftWidth: 1,
              borderRadius: 24,
              borderRightWidth: 1,
              borderTopWidth: 1,
              fontFamily: 'Poppins_400Regular',
              fontSize: 15,
              height: 48,
              marginTop: 20,
              paddingBottom: 8,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 8,
            },
            dimensions.width
          )}
        />
        {/* Reset Password */}
        <Button
          iconPosition={'left'}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes['App Fire'].Golden,
              borderRadius: 24,
              color: palettes['App Fire'].MyBlack,
              fontFamily: 'Poppins_500Medium',
              fontSize: 17,
              height: 46,
              marginTop: 40,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={'Reset Password'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ResetPasswordScreen);
