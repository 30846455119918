import palettes from './palettes';
import { createTheme, DefaultTheme } from '@draftbit/ui';
export default createTheme({
  breakpoints: {},
  palettes,
  baseTheme: DefaultTheme,
  theme: {
    name: 'Fire',
    colors: {
      background: {
        brand: palettes['Brand Fire'].Background,
        danger: palettes['Brand Fire'].Error,
      },
      border: {
        brand: palettes['Brand Fire'].Divider,
        danger: palettes['Brand Fire'].Error,
      },
      branding: {
        primary: palettes['Brand Fire'].Primary,
        secondary: palettes['Brand Fire'].Secondary,
      },
      foreground: {
        brand: palettes['Brand Fire'].Light,
        danger: palettes['Brand Fire'].Light,
      },
      text: {
        danger: palettes['Brand Fire'].Error,
        light: palettes['Brand Fire'].Light,
        medium: palettes['Brand Fire'].Medium,
        strong: palettes['Brand Fire'].Strong,
      },
    },
    typography: {
      body1: {
        fontFamily: 'PoppinsRegular',
        fontSize: 15,
        letterSpacing: 0,
        lineHeight: 26,
      },
      body2: {
        fontFamily: 'PoppinsRegular',
        fontSize: 13,
        letterSpacing: 0,
        lineHeight: 22,
      },
      button: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 15,
        letterSpacing: 0,
        lineHeight: 16,
      },
      caption: {
        fontFamily: 'System',
        fontSize: 13,
        letterSpacing: 0,
        lineHeight: 16,
      },
      headline1: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 60,
        letterSpacing: 0,
        lineHeight: 71,
      },
      headline2: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 48,
        letterSpacing: 0,
        lineHeight: 58,
      },
      headline3: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 40,
        letterSpacing: 0,
        lineHeight: 46,
      },
      headline4: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      headline5: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 20,
        letterSpacing: 0,
        lineHeight: 26,
      },
      headline6: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: 20,
      },
      overline: {
        fontFamily: 'PoppinsMedium',
        fontSize: 12,
        letterSpacing: 2,
        lineHeight: 16,
      },
      subtitle1: {
        fontFamily: 'PoppinsRegular',
        fontSize: 15,
        letterSpacing: 0,
        lineHeight: 26,
      },
      subtitle2: {
        fontFamily: 'System',
        fontSize: 13,
        letterSpacing: 0,
        lineHeight: 22,
      },
      custom14: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 36,
      },
      custom15: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 36,
      },
      custom16: {
        fontFamily: 'WorkSansRegular',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom17: {
        fontFamily: 'MerriweatherRegularItalic',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom18: {
        fontFamily: 'MerriweatherBoldItalic',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom19: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom20: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom21: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 26,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom22: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom23: {
        fontFamily: 'PoppinsBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom24: {
        fontFamily: 'PoppinsBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 46,
      },
      custom25: {
        fontFamily: 'PoppinsMedium',
        fontSize: 13,
        letterSpacing: 0,
        lineHeight: 22,
      },
      custom26: {
        fontFamily: 'System',
        fontSize: 13,
        letterSpacing: 0,
        lineHeight: 16,
      },
      custom27: {
        fontFamily: 'System',
        fontSize: 13,
        letterSpacing: 0,
        lineHeight: 16,
      },
      custom28: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 32,
      },
      custom29: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 32,
      },
      custom30: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 24,
      },
      custom31: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 48,
      },
      custom32: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 0,
        lineHeight: 48,
      },
      custom33: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 16,
        letterSpacing: 1,
        lineHeight: 16,
      },
      custom34: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 16,
        letterSpacing: 1,
        lineHeight: 16,
      },
      custom35: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 16,
        letterSpacing: 1,
        lineHeight: 16,
      },
      custom36: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 1,
        lineHeight: 16,
      },
      custom37: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 1,
        lineHeight: 16,
      },
      custom38: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 1,
        lineHeight: 16,
      },
      custom39: {
        fontFamily: 'PoppinsSemiBold',
        fontSize: 24,
        letterSpacing: 1,
        lineHeight: 16,
      },
    },
  },
});
