import React from 'react';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ImageBackground,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import * as CustomCode from '../CustomCode';
import * as VillaVuXanoApi from '../apis/VillaVuXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import readJsonVillaData from '../global-functions/readJsonVillaData';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const VillaDetailsAndBookingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const apiPrepResaNonDispoDates = (
    Variables,
    setGlobalVariableValue,
    apiResp
  ) => {
    // get the returned Api result and prepare the calendar's non dispo dates
    console.log('VillaDetailsAndBooking::VillaID==> ' + Variables.VillaId);
    console.log('Apinondispodates==> ' + JSON.stringify(apiResp));
    //setGlobalVariableValue({key:"apiResaBokdData",value: JSON.stringify(apiResp) });
    //console.log("Variables.apiResaBokdData ==> " + Variables.apiResaBokdData);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }

      const entry = StatusBar.pushStackEntry?.({ barStyle: 'light-content' });
      return () => StatusBar.popStackEntry?.(entry);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes['App Fire'].myLightBlue },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            borderBottomWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            height: 420,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={imageSource(
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1675945284/ViVi/IMG_0584_sxndqg.jpg'
          )}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes['App Fire'].TripItBlue,
              borderBottomWidth: 1,
              height: '100%',
              justifyContent: 'flex-end',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                paddingBottom: 24,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 24,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes['App Fire'].Icon_Opacity,
                  borderRadius: 8,
                  flexDirection: 'row',
                  paddingBottom: 6,
                  paddingLeft: 12,
                  paddingRight: 8,
                  paddingTop: 6,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes['Brand Fire'].Surface,
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Only '}
                  {Constants['VillaVals']?.nightly_price}
                  {' € per night\nFor 8 persons maximum'}
                </Text>
              </View>

              <View>
                {/* Button Outline */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const apiResaNonDispoDates = (
                          await VillaVuXanoApi.allSoldPeriodesGET(Constants, {
                            listings_id: Constants['VillaId'],
                          })
                        )?.json;
                        setGlobalVariableValue({
                          key: 'apiResaBokdData',
                          value: apiResaNonDispoDates,
                        });
                        setGlobalVariableValue({
                          key: 'BackFromResaCalendar',
                          value: false,
                        });
                        navigation.navigate('BookingDatesScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'transparent',
                      borderBottomWidth: 2,
                      borderColor: palettes['Brand Fire'].Surface,
                      borderLeftWidth: 2,
                      borderRadius: 21,
                      borderRightWidth: 2,
                      borderTopWidth: 2,
                      borderWidth: 1,
                      color: palettes['Brand Fire'].Surface,
                      fontFamily: 'Poppins_700Bold',
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'BOOK'}
                />
              </View>
            </View>
          </View>
        </ImageBackground>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            left: 15,
            marginTop: 30,
            position: 'absolute',
            top: 15,
            zIndex: 999,
          },
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <Icon
            size={24}
            color={palettes['Brand Fire'].Strong_Inverse}
            name={'Ionicons/arrow-undo'}
          />
        </Touchable>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes['App Fire'].TripItBlue,
            borderBottomWidth: 1,
            borderColor: theme.colors.text.strong,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            borderTopWidth: 1,
          },
          dimensions.width
        )}
      >
        <ScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                paddingBottom: 24,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 24,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes['Brand Fire'].Strong_Inverse,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 12,
                },
                dimensions.width
              )}
            >
              {readJsonVillaData(Variables, 'dontUse', 'city')}
            </Text>

            <Text
              accessible={true}
              selectable={false}
              ellipsizeMode={'tail'}
              numberOfLines={2}
              style={StyleSheet.applyWidth(
                {
                  color: palettes['Brand Fire'].Strong_Inverse,
                  fontFamily: 'Poppins_600SemiBold',
                  fontSize: 22,
                },
                dimensions.width
              )}
              textBreakStrategy={'highQuality'}
            >
              {readJsonVillaData(Variables, 'dontUse', 'name')}
            </Text>
            <Spacer bottom={8} left={8} right={8} top={8} />
            <View>
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      backgroundColor: palettes['Brand Fire'].Surface,
                      borderBottomWidth: 1,
                      borderColor: theme.colors.border.brand,
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={theme.colors.branding.primary}
                    name={'MaterialCommunityIcons/bed-king'}
                  />
                  <Spacer left={2} right={2} />
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.medium,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(Variables, 'dontUse', 'beds')}
                    {' beds'}
                  </Text>
                </View>
                <Spacer bottom={8} top={8} left={6} right={6} />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      backgroundColor: palettes['Brand Fire'].Surface,
                      borderBottomWidth: 1,
                      borderColor: theme.colors.border.brand,
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors.branding.primary}
                    name={'MaterialIcons/bathtub'}
                    size={20}
                  />
                  <Spacer left={2} right={2} />
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.medium,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(Variables, 'dontUse', 'bathrooms')}
                    {' baths'}
                  </Text>
                </View>
                <Spacer bottom={8} top={8} left={6} right={6} />
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes['Brand Fire'].Surface,
                      borderBottomWidth: 1,
                      borderColor: theme.colors.border.brand,
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={theme.colors.branding.primary}
                    name={'MaterialIcons/group'}
                  />
                  <Spacer left={2} right={2} />
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.medium,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(Variables, 'dontUse', 'maxGuests')}
                    {' max'}
                  </Text>
                </View>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            <View>
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 16,
                  },
                  dimensions.width
                )}
              >
                {'Rates'}
              </Text>
              <Spacer left={8} right={8} bottom={6} top={6} />
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {'Nightly'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_500Medium',
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(Variables, 'dontUse', 'nightly_price')}
                    {' €'}
                  </Text>
                </View>
              </View>
              <Divider
                color={palettes['App Fire'].Lightest}
                style={StyleSheet.applyWidth(
                  { height: 1, marginBottom: 12, marginTop: 12 },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {'Weekly (7 nights)'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_500Medium',
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(Variables, 'dontUse', 'weekly_price')}
                    {' €'}
                  </Text>
                </View>
              </View>
              <Divider
                color={palettes['App Fire'].Lightest}
                style={StyleSheet.applyWidth(
                  { height: 1, marginBottom: 12, marginTop: 12 },
                  dimensions.width
                )}
              />
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            <View>
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 16,
                  },
                  dimensions.width
                )}
              >
                {'Description'}
              </Text>
              <Spacer left={8} right={8} bottom={6} top={6} />
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes['Brand Fire'].Strong_Inverse,
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 14,
                    lineHeight: 26,
                    textAlign: 'left',
                  },
                  dimensions.width
                )}
              >
                {readJsonVillaData(Variables, 'dontUse', 'description')}
              </Text>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
            <View>
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 16,
                  },
                  dimensions.width
                )}
              >
                {'More'}
              </Text>
              <Spacer left={8} right={8} bottom={6} top={6} />
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {'Cancellation'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_500Medium',
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(
                      Variables,
                      'dontUse',
                      'cancellation_policy'
                    )}
                  </Text>
                </View>
              </View>
              <Divider
                color={palettes['App Fire'].Lightest}
                style={StyleSheet.applyWidth(
                  { height: 1, marginBottom: 12, marginTop: 12 },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {'Minimum stay'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_500Medium',
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(Variables, 'dontUse', 'min_stay')}
                  </Text>
                </View>
              </View>
              <Divider
                color={palettes['App Fire'].Lightest}
                style={StyleSheet.applyWidth(
                  { height: 1, marginBottom: 12, marginTop: 12 },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {'Cleaning fee'}
                  </Text>
                </View>

                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.text.strong,
                        fontFamily: 'Poppins_500Medium',
                      },
                      dimensions.width
                    )}
                  >
                    {readJsonVillaData(Variables, 'dontUse', 'cleaning_fee')}
                  </Text>
                </View>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={16} top={16} />
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes['Brand Fire'].Surface,
                  borderBottomWidth: 1,
                  borderColor: theme.colors.text.strong,
                  borderLeftWidth: 1,
                  borderRadius: 16,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { borderRadius: 10, height: 240, overflow: 'hidden' },
                  dimensions.width
                )}
              >
                <MapView
                  apiKey={'AIzaSyBzktToWosjNgrrUawZnbslB9NSXSXCkwo'}
                  autoClusterMarkers={false}
                  autoClusterMarkersDistanceMeters={10000}
                  customMapStyle={'Lorem ipsum dolor sit amet'}
                  loadingEnabled={true}
                  moveOnMarkerPress={true}
                  rotateEnabled={true}
                  scrollEnabled={true}
                  showsCompass={false}
                  showsPointsOfInterest={true}
                  showsUserLocation={false}
                  zoomEnabled={true}
                  latitude={Constants['VillaVals']?.latitude}
                  longitude={Constants['VillaVals']?.longitude}
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  zoom={16}
                >
                  <MapMarker
                    androidUseDefaultIconImplementation={false}
                    flat={false}
                    pinImageSize={50}
                    tracksViewChanges={true}
                    description={'277 Bana Jelacica, Slatine'}
                    latitude={Constants['VillaVals']?.latitude}
                    longitude={Constants['VillaVals']?.longitude}
                    pinColor={theme.colors.branding.primary}
                    pinImage={''}
                    title={Constants['VillaVals']?.name}
                  />
                </MapView>
              </View>
            </View>
            <Spacer left={8} right={8} bottom={12} top={12} />
          </View>
        </ScrollView>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(VillaDetailsAndBookingScreen);
