import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const addSoldPeriode4NewResaPOST = async (
  Constants,
  { VacSeasId, fromDate, resaId, toDate, userId, villaId },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/soldperiode`;
  const options = {
    body: JSON.stringify({
      user_id: userId,
      listings_id: villaId,
      reservations_id: resaId,
      vacseasons_id: VacSeasId,
      fromDate: fromDate,
      toDate: toDate,
      typeperiode: 'booked',
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAddSoldPeriode4NewResaPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoAddSoldPeriode4NewResaPOST', args],
    () => addSoldPeriode4NewResaPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'villaVuXanoAddSoldPeriode4NewResaPOSTS',
        ]),
    }
  );
};

export const FetchAddSoldPeriode4NewResaPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  VacSeasId,
  fromDate,
  resaId,
  toDate,
  userId,
  villaId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAddSoldPeriode4NewResaPOST(
    { VacSeasId, fromDate, resaId, toDate, userId, villaId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchAddSoldPeriode4NewResa: refetch,
  });
};

export const allSoldPeriodesGET = async (
  Constants,
  { listings_id },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/soldperiodes/listings/${encodeQueryParam(
    listings_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAllSoldPeriodesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoAllSoldPeriodesGET', args],
    () => allSoldPeriodesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoAllSoldPeriodesGETS']),
    }
  );
};

export const FetchAllSoldPeriodesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  listings_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAllSoldPeriodesGET(
    { listings_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAllSoldPeriodes: refetch });
};

export const authMeGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/auth/me`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAuthMeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoAuthMeGET', args],
    () => authMeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['villaVuXanoAuthMeGETS']),
    }
  );
};

export const FetchAuthMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAuthMeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAuthMe: refetch });
};

export const listAllActiveVillasGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/listingsactive`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useListAllActiveVillasGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoListAllActiveVillasGET', args],
    () => listAllActiveVillasGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoListAllActiveVillasGETS']),
    }
  );
};

export const FetchListAllActiveVillasGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useListAllActiveVillasGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchListAllActiveVillas: refetch,
  });
};

export const loginPOST = async (
  Constants,
  { signupEmail, signupPassword },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/auth/login`;
  const options = {
    body: JSON.stringify({ email: signupEmail, password: signupPassword }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoLoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['villaVuXanoLoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  signupEmail,
  signupPassword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLoginPOST(
    { signupEmail, signupPassword },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const loginOrSignupPOST = async (
  Constants,
  { lsEmail, lsName, lsPassword },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/auth/loginorsignup`;
  const options = {
    body: JSON.stringify({
      name: lsName,
      email: lsEmail,
      password: lsPassword,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginOrSignupPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoLoginOrSignupPOST', args],
    () => loginOrSignupPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoLoginOrSignupPOSTS']),
    }
  );
};

export const FetchLoginOrSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  lsEmail,
  lsName,
  lsPassword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLoginOrSignupPOST(
    { lsEmail, lsName, lsPassword },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLoginOrSignup: refetch });
};

export const signupPOST = async (
  Constants,
  { signupEmail, signupName, signupPassword },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/auth/signup`;
  const options = {
    body: JSON.stringify({
      name: signupName,
      email: signupEmail,
      password: signupPassword,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSignupPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoSignupPOST', args],
    () => signupPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoSignupPOSTS']),
    }
  );
};

export const FetchSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  signupEmail,
  signupName,
  signupPassword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSignupPOST(
    { signupEmail, signupName, signupPassword },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignup: refetch });
};

export const getVacSeasonIdGET = async (
  Constants,
  { vacyear },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/vacseasons/vacyear/${encodeQueryParam(
    vacyear
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetVacSeasonIdGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoGetVacSeasonIdGET', args],
    () => getVacSeasonIdGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoGetVacSeasonIdGETS']),
    }
  );
};

export const FetchGetVacSeasonIdGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  vacyear,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetVacSeasonIdGET(
    { vacyear },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetVacSeasonId: refetch });
};

export const newResaDownPaymentPOST = async (
  Constants,
  { downpaid, downpymnt, resaId, restdue, restpaid, totaldue, totalpaid },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/resapayments`;
  const options = {
    body: JSON.stringify({
      reservations_id: resaId,
      downpayment: downpymnt,
      downpaid: downpaid,
      restdue: restdue,
      restpaid: restpaid,
      totaldue: totaldue,
      totalpaid: totalpaid,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useNewResaDownPaymentPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoNewResaDownPaymentPOST', args],
    () => newResaDownPaymentPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoNewResaDownPaymentPOSTS']),
    }
  );
};

export const FetchNewResaDownPaymentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  downpaid,
  downpymnt,
  resaId,
  restdue,
  restpaid,
  totaldue,
  totalpaid,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useNewResaDownPaymentPOST(
    { downpaid, downpymnt, resaId, restdue, restpaid, totaldue, totalpaid },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchNewResaDownPayment: refetch });
};

export const newReservationPOST = async (
  Constants,
  {
    bookedEnd,
    bookedStart,
    bookingfee,
    nightlyprice,
    timenow,
    totalnights,
    totalpersons,
    totalprice,
    villaId,
  },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/reservations`;
  const options = {
    body: JSON.stringify({
      listings_id: villaId,
      start_at: bookedStart,
      end_at: bookedEnd,
      nightlyprice: nightlyprice,
      totalnights: totalnights,
      totalpersons: totalpersons,
      bookingfee: bookingfee,
      totalprice: totalprice,
      confirmed_at: timenow,
      denied_at: '"',
      canceled_at: '"',
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiemlwIjoiREVGIn0.rdxrxI1M0YXmHGHsDR8wwqGhEdc1wPdDmNGyjQrbLOVVscAgrkm467dfBbrWTYXbMo2V5syFuxUM5TF81JuoWmWjwa0urMVA.CrX-JV_7Q-UtNJ1LEmxIDQ.C-Far3AYjQ5gD6KCjJHjzfl3zmrL9B4pbWWblxMY2siv49GBOxKFpW9pxeioxoUVtl5CbcoFXMySIKrs6B1ACGQU7Y_daLEe60ZdA-kVh-2kgtrstjO6loUOQfAHOzTbyxUlv2z3HZzu8Wiwe0zY0UAbw_ulxnAHDmOl_NPuMPc.6xCM-Y2E5NmE5zu4fgrawLLi6nsbReYUMaIdIDfRP0s',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useNewReservationPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoNewReservationPOST', args],
    () => newReservationPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoNewReservationPOSTS']),
    }
  );
};

export const FetchNewReservationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  bookedEnd,
  bookedStart,
  bookingfee,
  nightlyprice,
  timenow,
  totalnights,
  totalpersons,
  totalprice,
  villaId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useNewReservationPOST(
    {
      bookedEnd,
      bookedStart,
      bookingfee,
      nightlyprice,
      timenow,
      totalnights,
      totalpersons,
      totalprice,
      villaId,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchNewReservation: refetch });
};

export const propertyStatsGET = async (
  Constants,
  { listings_id },
  handlers = {}
) => {
  const url = `https://x8ki-letl-twmt.n7.xano.io/api:24LooEnC/propstats/listings/${encodeQueryParam(
    listings_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePropertyStatsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['villaVuXanoPropertyStatsGET', args],
    () => propertyStatsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['villaVuXanoPropertyStatsGETS']),
    }
  );
};

export const FetchPropertyStatsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  listings_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePropertyStatsGET(
    { listings_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPropertyStats: refetch });
};
