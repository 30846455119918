// This import is required if you are defining react components in this module.
import React from 'react';

import { DeckSwiper, DeckSwiperCard, Touchable } from '@draftbit/ui';

import { Image, Text, View } from 'react-native';

import * as GlobalVariableContext from '../config/GlobalVariableContext';
import * as GetSwiperSectorStruct from '../custom-files/GetSwiperSectorStruct.js';

// Define and export your components as named exports here.

// You can use components exported from this file within a Custom Code component as
// <FillDeckSwiperCards.FillJsx  />
export const FillJsx = props => {
  const { theme } = props;
  const { swipedInIdx } = props;
  const { setSwipedInIdx } = props;

  const Constants = GlobalVariableContext.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariableContext.useSetValue();

  const incrJustSwipedInIdx = (Variables, setGlobalVariableValue, inIdx) => {
    let idx = inIdx + 1;
    let mxi = Variables.currSectionData.SectionItems.length; // 2;
    if (idx > mxi) idx = 1;
    setSwipedInIdx(idx);
    //console.log("swipedInIdx ==> "+idx)
    //console.log(" SectionImgsLength ==> "+ mxi)
    let currItem = Variables.currSectionData.SectionItems[idx - 1]; // Arr 0...(n-1)
    setGlobalVariableValue({ key: 'currSectionItem', value: currItem });
  };

  let currSectionData = Variables.currSectionData;
  if (currSectionData.SectionItems == undefined) {
    let sectObj = GetSwiperSectorStruct.SectorData(1);
    let sectorData = sectObj.sectorData;
    currSectionData = sectorData.Sections[0];
    setGlobalVariableValue({ key: 'currSectionData', value: currSectionData });
  }

  let SctnItems = currSectionData.SectionItems; // Variables.  // need to be filled with the items of the curr section
  //console.log("oneSctnItems =>=> "+JSON.stringify(SctnItems));

  if (swipedInIdx == undefined || null == swipedInIdx || swipedInIdx <= 0)
    swipedInIdx = 1;

  //let currItem = SctnItems[swipedInIdx - 1];

  let currItem = currSectionData.SectionItems[swipedInIdx - 1]; //Variables.
  //console.log("One Item data ==> "+JSON.stringify(currItem));
  let imgUrl = currItem.img; // "https://res.cloudinary.com/dcmydoriy/image/upload/v1675906567/ViVi/IMG_2084_tzptxh.jpg";

  return (
    <>
      <DeckSwiper
        onIndexChanged={() => {
          try {
            incrJustSwipedInIdx(Variables, setGlobalVariableValue, swipedInIdx);
          } catch (err) {
            console.error(err);
          }
        }}
        style={{ opacity: 1, position: 'absolute', top: 15 }}
        verticalEnabled={true}
        horizontalEnabled={true}
        visibleCardCount={2}
        startCardIndex={0}
        infiniteSwiping={true}
      >
        {SctnItems.map(item => (
          <DeckSwiperCard
            style={{ borderRadius: 11, borderWidth: 2, margin: 5 }}
          >
            {/*<Touchable>.height: 321,width: 333, minHeight: 321,minWidth: 333 */}
            <Image
              style={{
                borderRadius: 11,
                borderWidth: 1,
                height: 321,
                width: 333,
              }}
              resizeMode={'cover'}
              source={{ uri: item.img }}
            />
            {/*</Touchable>*/}
          </DeckSwiperCard>
        ))}
      </DeckSwiper>
    </>
  );
};
