// This import is required if you are defining react components in this module.
import React from 'react';

const SectData = {
  Sectors: [
    {
      id: 1, //sector Id
      SectorScrollTitle: 'Villa Terraces & Views',
      SectorScrollImgUrl:
        'https://res.cloudinary.com/dcmydoriy/image/upload/v1675906567/ViVi/IMG_2084_tzptxh.jpg',
      SectorTitle: 'OutDoors & More',
      Sections: [
        {
          id: 1,
          SectionScrollTitle: '5 stars comfort',
          SectionTitle: 'Enjoy & Relaxe',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1675906567/ViVi/IMG_2084_tzptxh.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1675906567/ViVi/IMG_2084_tzptxh.jpg',
              descr:
                'The Sea at arms reach while you sip your morning coffe or enjoying your evening drink.',
              title: 'Outdoors living',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1675906483/ViVi/IMG_0355_k1pnpr.jpg',
              descr:
                'From all the sides of this huge terrace you can enjoy the seanary and come in reunion with nature and the sea.',
              title: 'Soothing views',
            },
            //{"id":2,"img":"IMG1056B","descr":"Meridians corner just thought for your late afternoons. Enjoying a special book of yours, Listening to your prefered music or taking a little end of day nap.\rThe setting is your\'s, so relaxe and enjoy.","title":"Meridians for dreamy moments"},
            //{"id":3,"img":"IMG1038","descr":"A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r","title":"Stairway to Relaxation"}
          ],
        },
        {
          id: 2,
          SectionScrollTitle: 'Fresh air meals',
          SectionTitle: 'Sea front meals',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1678317251/ViVi/IMG_3418_xkzoqc.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1678317251/ViVi/IMG_3418_xkzoqc.jpg',
              descr:
                'From all the sides of this huge terrace you can enjoy the seanary and come in reunion with nature and the sea.',
              title: 'Soothing views',
            },
          ],
        },
        {
          id: 3,
          SectionScrollTitle: 'Sun & Cents',
          SectionTitle: 'Colorful scenary',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1678370235/ViVi/IMG_3415_vccrz5.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1678370235/ViVi/IMG_3415_vccrz5.jpg',
              descr:
                'From all the sides of this huge terrace you can enjoy the seanary and come in reunion with nature and the sea.',
              title: 'Live in colors',
            },
          ],
        },
        {
          id: 4,
          SectionScrollTitle: 'Panoramic Views',
          SectionTitle: 'Soothing prespective',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1678370465/ViVi/IMG_2225_a0eps3.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1678370465/ViVi/IMG_2225_a0eps3.jpg',
              descr:
                'From all the sides of this huge terrace you can enjoy the seanary and come in reunion with nature and the sea.',
              title: 'Panoramic prespective',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687270392/ViVi/IMG_0052_2_v3p5sw.jpg',
              descr: 'From all the sides of this huge terrace Sea Sun & nature',
              title: 'Another prespective',
            },
          ],
        },
      ],
    },
    {
      id: 2,
      SectorScrollTitle: 'Villa Indoors & Arts',
      SectorScrollImgUrl:
        'https://res.cloudinary.com/dcmydoriy/image/upload/v1675908220/ViVi/IMG_3427_hv92hn.jpg',
      SectorTitle: 'Indoors Comfort',
      Sections: [
        {
          id: 1,
          SectionScrollTitle: 'The living',
          SectionTitle: 'Saloon / Living room',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1675908220/ViVi/IMG_3427_hv92hn.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1675908220/ViVi/IMG_3427_hv92hn.jpg',
              descr:
                'Different setting within this living space, a saloon side, a relaxation part, and a media corner. \r All of that with access to the Kitchen sapce, to first floor rooms, and a bathroom facility too.',
              title: 'Saloon / Living room',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687201863/ViVi/x50ijsfxdawzionvofv3.jpg',
              descr:
                'Another view of the Saloon; all is there for you to enjoy luxurious vacations.',
              title: 'Enjoy the sapce',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687251667/ViVi/IMG_1479_gzo96x.jpg',
              descr:
                'Opposit side of the saloon, enjoy the meredians for a causey afternoon nap; Also a working desk corner for you to set your laptop & check your emails.\r',
              title: 'Meredians & Office Corner',
            },
            {
              id: 4,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687265903/ViVi/IMG1038_h2gure.jpg',
              descr:
                'Extra relaxation corner and the stair way to first floor : Rooms terraces and Bathroom .\r\r',
              title: 'Another Relaxing Corner',
            },
          ],
        },
        {
          id: 2,
          SectionScrollTitle: 'Room & terrace',
          SectionTitle: 'Golden Room',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711363283/ViVi/IMG_1028_rhcxx0.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711363283/ViVi/IMG_1028_rhcxx0.jpg',
              descr:
                'A golden setting for you to enjoy your sleep, or relaxing while reading a book, or just lay down and wath the sea waves from your bed. What a fantastic way to fulfill your dreams.',
              title: 'Luxury sleep',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711363710/ViVi/IMG_5105r_lv6odi.jpg',
              descr:
                'This room has access to the big terrace with a wonderful sea and serrounding view; not to mention the early dawn sun rise gaising with its magnificent worm Colors\r',
              title: 'Terrace access',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711364474/ViVi/IMG_5118_emmamv.jpg',
              descr:
                'Two beds grouped as king size ones but can be rearranged as you desire.\r\r',
              title: 'Flexi Beds',
            },
          ],
        },
        {
          id: 3,
          SectionScrollTitle: 'Room & terrace',
          SectionTitle: 'Marina Room',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711365432/ViVi/IMG_2189_n1f1nx.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711365432/ViVi/IMG_2189_n1f1nx.jpg',
              descr:
                'King size room with marina inclined colors and a long terrace access with a magistic sea view. Enjoying a mornning breakfast for a "Life is wonderful"... ',
              title: 'Marina marina marina...',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711372286/ViVi/IMG_5574_gbq3ze.jpg',
              descr:
                'This room has access to the big terrace with a wonderful sea and serrounding view; not to mention the early dawn sun rise gaising with its magnificent worm Colors\r',
              title: 'Terrace access',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711373732/ViVi/IMG_5556_j8dyps.jpg',
              descr:
                'Two beds grouped as king size ones but can be rearranged as you desire.\r\r',
              title: 'Flexi Beds',
            },
          ],
        },
        {
          id: 4,
          SectionScrollTitle: 'Room & Balcony',
          SectionTitle: 'Aqua Room',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711376047/ViVi/IMG_4249_e2mthy.jpg',
          //"SectionImg" : "https://res.cloudinary.com/dcmydoriy/image/upload/v1711375142/ViVi/IMG_5072_i5rc58.jpg",
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711374062/ViVi/IMG_5570_syrhb4.jpg',
              descr:
                "This room can take a couple and their kid. Bringing your home causy life on vacation. You have access to your private balcony, C'est la vie ",
              title: 'Aqua marin room',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711374158/ViVi/IMG_4246_l9ke52.jpg',
              descr:
                'You can take a moment to review your photos, read a book, or even work on your computer, Internet Wifi is free, just consume it.',
              title: 'Relaxing corner',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711374335/ViVi/IMG_4257_czkos5.jpg',
              descr:
                "Rooms are equipped with airconditionners; You'r in total control of your mood so stay cool. \r",
              title: 'Daddy cool',
            },
          ],
        },
        {
          id: 5,
          SectionScrollTitle: 'Kitchen & Terrace',
          SectionTitle: 'Kitchen',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711374698/ViVi/IMG_1084_ydhuxk.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711374698/ViVi/IMG_1084_ydhuxk.jpg',
              descr:
                'The great cook in you, will be astonished with all the cooking tools at your disposal. A party, a meal or a cake its up to you to make.',
              title: 'To cook or not to cook?',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711374872/ViVi/IMG_1085_ueynko.jpg',
              descr:
                "A kitchen table for you to dine or use it to prepare all the food of the party meal you'r throwing.\r",
              title: 'Indoors Dining',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711374968/ViVi/IMG_1078_lg9i61.jpg',
              descr:
                "Many tools and utinsiles are there to help you, From as big as an ice machine to as small as an orange Press; just shack it and you'll make it. \r",
              title: 'Kitchen tools',
            },
          ],
        },
        {
          id: 6,
          SectionScrollTitle: 'Bath Rooms',
          SectionTitle: 'Baths on each floor',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375458/ViVi/IMG_2153_ckvhil.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375458/ViVi/IMG_2153_ckvhil.jpg',
              descr:
                "Bath room and toilettes directly accessible from the living room. An italian shower, a lavabo and a toilette; just run it woun't wait.",
              title: 'Ground floor bathroom',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375531/ViVi/IMG_0733_vfqaky.jpg',
              descr:
                'First floor big Bath room serving the three bed rooms: Tub, shower, lavabo, dressing mirrors and toilettes.\r',
              title: '1st floor bathroom',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375593/ViVi/IMG_2920_zthcnz.jpg',
              descr:
                'All mirrors so that you can dress up for that evening city going out canddles diner. \r',
              title: 'Mirrors bathroom',
            },
          ],
        },
      ],
    },
    //sector 3
    {
      id: 3,
      SectorScrollTitle: 'Villa on premisse services',
      SectorScrollImgUrl:
        'https://res.cloudinary.com/dcmydoriy/image/upload/v1675909242/ViVi/IMG_1084_nxu8mj.jpg',
      SectorTitle: 'Provided services',
      Sections: [
        {
          id: 1,
          SectionScrollTitle: 'Relaxing Spots',
          SectionTitle: 'Living room relaxation',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375689/ViVi/IMG_3427_oxrntc.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375689/ViVi/IMG_3427_oxrntc.jpg',
              descr:
                'Just relaxe while enjoying the soothing saloon atmosphere. Observe your cool factor hit the ceiling heights while sipping a Martini on ice.\r\r',
              title: 'Inspiered relaxation spots',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375796/ViVi/IMG_1056B_biko4d.jpg',
              descr:
                "Meridians corner just thought for your late afternoons. Enjoying a special book of yours, Listening to your prefered music or taking a little end of day nap.\r\rThe setting is your's, so relaxe and enjoy.",
              title: 'Meridians for dreamy moments',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711375857/ViVi/IMG_1038_lfydmn.jpg',
              descr:
                'A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r',
              title: 'Stairway to Relaxation',
            },
          ],
        },
        {
          id: 2,
          SectionScrollTitle: 'All AirConditioned',
          SectionTitle: 'Refreshed Living & Rooms',
          SectionImg: 'IMG1056',
          SectionItems: [
            {
              id: 1,
              img: 'IMG1056',
              descr:
                'Just relaxe while enjoying the fresh saloon atmosphere.\r\r',
              title: 'Cool breeze in the air',
            },
            {
              id: 1,
              img: 'IMG5118',
              descr:
                'Each room has its own air condition and remote control. Each room with its closets and with a terrasse or Balcony.\r Sweet dreams are assured.',
              title: 'Air conditioned Golden room',
            },
            {
              id: 2,
              img: 'IMG2189',
              descr:
                'Each room has its own air condition and remote control. Each room with its closets and with a terrasse or Balcony.\r Sweet dreams are assured.',
              title: 'Air conditioned Marina room',
            },
            {
              id: 3,
              img: 'IMG4249',
              descr:
                'Each room has its own air condition and remote control. Each room with its closets and with a terrasse or Balcony.\r Sweet dreams are assured.',
              title: 'Air conditioned Aqua room',
            },
          ],
        },
        {
          id: 3,
          SectionScrollTitle: 'Kitchen Facilities',
          SectionTitle: 'Fully equiped',
          SectionImg: 'IMG1078',
          SectionItems: [
            {
              id: 1,
              img: 'IMG1078',
              descr:
                'Fully equipped kitchen awaits you with many surprises. Coffe machine, Ice maker, Plancha, Dish washer and others. With your cooking & immagination you will enjoy the experience and become the summer cheff de cuisine.\r',
              title: 'Kitchen facilities',
            },
            {
              id: 2,
              img: 'IMG0901',
              descr:
                'A 2m high fidge & freezer for a week or more of food, enough for at least 8 persons. With the ice machine you will enjoy fresh drinks all day long and for late night parties & star gaising dreams.\r',
              title: 'Family size Fridge & Ice Machine',
            },
            {
              id: 3,
              img: 'IMG0898',
              descr:
                'Electric stove with four placements so you can prepare your meals pretty quicly. Be it for four or ten persons you are in control.\r\r',
              title: 'Fully electric stove',
            },
            {
              id: 4,
              img: 'IMG4438',
              descr:
                'Enjoy outdoors cooking, if it is Steak, sausages or vegitables its up to you.\r',
              title: 'Family size Plancha',
            },
          ],
        },
        {
          id: 4,
          SectionScrollTitle: 'Bathroom services',
          SectionTitle: 'Facilities inside',
          SectionImg: 'IMG2153',
          SectionItems: [
            {
              id: 1,
              img: 'IMG2153',
              descr:
                'Italian shower, Toilette  and lavabo, the bathroom is equipped with towels, a hair dryer and even a heater in case its a fresh day.',
              title: 'Ground floor bathroom',
            },
            {
              id: 2,
              img: 'IMG0733',
              descr:
                'A bath tub, Toilette, Italian shower and Lavabo, the bathroom is equipped with towels, a hair dryer and even a heater in case its a fresh day.',
              title: '1st floor bathroom',
            },
            {
              id: 3,
              img: 'IMG2920',
              descr:
                'A bath tub, Toilette, Italian shower and Lavabo, the bathroom is equipped with towels, a hair dryer and even a heater in case its a fresh day.',
              title: '1st floor bathroom',
            },
          ],
        },
      ],
    },
    //sector 4
    {
      id: 4,
      SectorScrollTitle: 'Beach at 30 meters walk',
      SectorScrollImgUrl:
        'https://res.cloudinary.com/dcmydoriy/image/upload/v1675982858/ViVi/IMG_1188_zoq36v.jpg',
      SectorTitle: 'Beach, Sun & Fun',
      Sections: [
        {
          id: 1,
          SectionScrollTitle: 'Beach & Swiming',
          SectionTitle: 'Sea & Sun',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711379193/ViVi/IMG_2481_iyaqur.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711379406/ViVi/IMG_2811r_ggerwd.jpg',
              descr:
                "Just relaxe under the beach's sun while the soung of waves crushing on the pebelles brings your mood to an ultimate chill .\r\r",
              title: 'Inspiered relaxation',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1675982858/ViVi/IMG_1188_zoq36v.jpg',
              descr:
                'A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r',
              title: 'Stairway to Relaxation',
            },
          ],
        },
        {
          id: 2,
          SectionScrollTitle: 'Causy Sun Bath',
          SectionTitle: 'Beach Long Chairs',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711379607/ViVi/IMG_1199_pzo62j.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711379607/ViVi/IMG_1199_pzo62j.jpg',
              descr:
                '6 long chairs are provided so that you enjoying your sun bath.',
              title: 'Beach long chairs provided',
            },
            //{"id":2,"img":"IMG1038","descr":"A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r","title":"Stairway to Relaxation"},
          ],
        },
        {
          id: 3,
          SectionScrollTitle: 'Beach  Shower',
          SectionTitle: 'Shower then Sun bath',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1711379406/ViVi/IMG_2811r_ggerwd.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1711379406/ViVi/IMG_2811r_ggerwd.jpg',
              descr:
                'Enjoying the beach sun bath after a quick shower on the beach. All is done for you to enjoy your stay so just relaxe.',
              title: 'Shower on the beach',
            },
            //{"id":2,"img":"IMG1038","descr":"A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r","title":"Stairway to Relaxation"},
          ],
        },
      ],
    },
    // sector 5
    {
      id: 5,
      SectorScrollTitle: 'Near by Trogir town',
      SectorScrollImgUrl:
        'https://res.cloudinary.com/dcmydoriy/image/upload/v1676084091/ViVi/DinningInTrogir_xho9zh.jpg',
      SectorTitle: 'Tourisme & Dinning',
      Sections: [
        {
          id: 1,
          SectionScrollTitle: 'Bay side esplanade',
          SectionTitle: 'Bay side esplanade',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1676084091/ViVi/DinningInTrogir_xho9zh.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1676084091/ViVi/DinningInTrogir_xho9zh.jpg',
              descr:
                'At the bay of the near by Trogir town enjoy your evening dinner or a walk while admiring the different moored yachts.',
              title: 'Trogir bay side dinning',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687564690/ViVi/IMG_1520_pc3otq.jpg',
              descr:
                'Enjoy the yachts seanary while dining or having a walk on the esplanade.',
              title: 'Yachts at bay',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687565483/ViVi/IMG2222_uj6sbr.jpg',
              descr:
                'A wide choice of restaurants, Caffés or IceCreem stands, anyone will find his plesure.',
              title: 'Esplanade with splendor',
            },
            {
              id: 4,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688487987/ViVi/IMG7337Trogir_oszahd.jpg',
              descr:
                'A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r',
              title: 'Stairway to Relaxation',
            },
          ],
        },
        {
          id: 2,
          SectionScrollTitle: 'Trogir Midieval town',
          SectionTitle: 'Midieval Trogir',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1687566807/ViVi/IMG2223_mnwzoa.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687566807/ViVi/IMG2223_mnwzoa.jpg',
              descr:
                'At the bay of the near by Trogir town enjoy your evening dinner or a walk while admiring the different moored yachts.',
              title: 'Trogir the Medieval',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687566836/ViVi/IMG2224_bruqn0.jpg',
              descr:
                'Enjoy the yachts seanary while dining or having a walk on the esplanade.',
              title: 'Trogir Church',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687566852/ViVi/IMG2225_yehx1v.jpg',
              descr:
                'A wide choice of restaurants, Caffés or IceCreem stands, anyone will find his plesure.',
              title: 'Municipality place',
            },
            {
              id: 4,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1687566869/ViVi/IMG2226_o7jxnd.jpg',
              descr:
                'A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r',
              title: 'Sears assembly',
            },
            {
              id: 5,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688488091/ViVi/IMG0768Trogir_gu1awn.jpg',
              descr:
                'A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r',
              title: 'Sears assembly',
            },
          ],
        },
      ],
    },
    //sector 6
    {
      id: 6,
      SectorScrollTitle: 'Excursions to Explore',
      SectorScrollImgUrl:
        'https://res.cloudinary.com/dcmydoriy/image/upload/v1688482418/ViVi/IMG1797BL_s432mi.jpg',
      SectorTitle: 'Excursions',
      Sections: [
        {
          id: 1,
          SectionScrollTitle: 'Blue Lagoon, boat from Trogir',
          SectionTitle: 'Blue Lagoon',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1688482418/ViVi/IMG1797BL_s432mi.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688482418/ViVi/IMG1797BL_s432mi.jpg',
              descr:
                'At the bay of the near by Trogir town enjoy your evening dinner or a walk while admiring the different moored yachts.',
              title: 'Trogir bay side dinning',
            },
            //{"id":2,"img":"https://res.cloudinary.com/dcmydoriy/image/upload/v1687564690/ViVi/IMG_1520_pc3otq.jpg","descr":"Enjoy the yachts seanary while dining or having a walk on the esplanade.","title":"Yachts at bay"},
            //{"id":3,"img":"https://res.cloudinary.com/dcmydoriy/image/upload/v1687565483/ViVi/IMG2222_uj6sbr.jpg","descr":"A wide choice of restaurants, Caffés or IceCreem stands, anyone will find his plesure.","title":"Esplanade with splendor"},
            //{"id":4,"img":"IMG1038","descr":"A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r","title":"Stairway to Relaxation"}
          ],
        },
        {
          id: 2,
          SectionScrollTitle: 'Krka National Park, by car',
          SectionTitle: 'Krka Waterfalls',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1688482405/ViVi/IMG1798Krka_sqowib.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688482405/ViVi/IMG1798Krka_sqowib.jpg',
              descr:
                'Krka natunal Park situated about 76km by car north of Trogir; Waterfalls and the lake are marvels of nature; need a full day to enjoy it all',
              title: 'Amazing natural park',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1675985867/ViVi/IMG_0672_nrde5b.jpg',
              descr:
                'Enjoy the yachts seanary while dining or having a walk on the esplanade.',
              title: 'Trogir Church',
            },
            //{"id":3,"img":"https://res.cloudinary.com/dcmydoriy/image/upload/v1687566852/ViVi/IMG2225_yehx1v.jpg","descr":"A wide choice of restaurants, Caffés or IceCreem stands, anyone will find his plesure.","title":"Municipality place"},
            //{"id":4,"img":"https://res.cloudinary.com/dcmydoriy/image/upload/v1687566869/ViVi/IMG2226_o7jxnd.jpg","descr":"A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r","title":"Sears assembly"}
          ],
        },
        {
          id: 3,
          SectionScrollTitle: 'Split City',
          SectionTitle: 'Historic Split & Harbor',
          SectionImg:
            'https://res.cloudinary.com/dcmydoriy/image/upload/v1688485788/ViVi/IMG1800Split01_tn02uv.jpg',
          SectionItems: [
            {
              id: 1,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688483688/ViVi/IMG0404Split1_qogl5f.jpg',
              descr:
                'At the bay of the near by Trogir town enjoy your evening dinner or a walk while admiring the different moored yachts.',
              title: 'Trogir the Medieval',
            },
            {
              id: 2,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688483738/ViVi/IMG0405Split2_slnwt2.jpg',
              descr:
                'Enjoy the yachts seanary while dining or having a walk on the esplanade.',
              title: 'Trogir Church',
            },
            {
              id: 3,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688483885/ViVi/IMG0387Split31_cdkb8m.jpg',
              descr:
                'A wide choice of restaurants, Caffés or IceCreem stands, anyone will find his plesure.',
              title: 'Municipality place',
            },
            {
              id: 4,
              img: 'https://res.cloudinary.com/dcmydoriy/image/upload/v1688484118/ViVi/IMG0384Split33_t7wzck.jpg',
              descr:
                'A wide Canappe to watch your days taken photos or watch a movie on the provided Tv screen; Otherwise enjoy a late day frying under the afternoon sun. All is done for your fun.\r\r',
              title: 'Sears assembly',
            },
          ],
        },
      ],
    },
  ],
};

/* *************************************************

 ************************************************** */

export const AllSectorsData = () => {
  return SectData.Sectors;
};

export const SectorData = sectorId => {
  let sectorData = {};
  if (sectorId > 0) {
    sectorData = SectData.Sectors[sectorId - 1];
  }
  return { sectorData };
};
