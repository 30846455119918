import React from 'react';
import {
  Button,
  DatePicker,
  Icon,
  ScreenContainer,
  Shadow,
  Surface,
  TextInput,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ImageBackground, Platform, Text, View } from 'react-native';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as VillaVuXanoApi from '../apis/VillaVuXanoApi.js';
import * as XanoChkOutStripeApi from '../apis/XanoChkOutStripeApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const PaymentCardInfoScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [cardHolderName, setCardHolderName] = React.useState('');
  const [cardNumber, setCardNumber] = React.useState('');
  const [cvvValue, setCvvValue] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [duePrice, setDuePrice] = React.useState(0);
  const [msgRestToPay, setMsgRestToPay] = React.useState('');
  const [paymentInstructions, setPaymentInstructions] = React.useState('');
  const [searchBarValue, setSearchBarValue] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState('tab1');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const calcDownPaymentPrice = Variables => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    //return (Math.round(0.30 * Variables.glblDuePrice));
    return 650;
  };

  const setPaymentSessParams = (setGlobalVariableValue, paySessApiRes) => {
    console.log('paySessApiResp ==> ', JSON.stringify(paySessApiRes));
    let payUrl = paySessApiRes.url;
    let paySessId = paySessApiRes.id;
    console.log('paySessApiResp.Url ==> ', payUrl);
    console.log('paySessApiResp.Id ==> ', paySessId);
    setGlobalVariableValue({ key: 'paymentUrl', value: payUrl });
    setGlobalVariableValue({ key: 'paymentSessId', value: paySessId });
  };

  const assignDuePrice = (Variables, setGlobalVariableValue) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    let dwonPaymentAmount = 650; // Math.round(0.30 * Variables.glblDuePrice)

    let restDueAmount = Variables.glblDuePrice - dwonPaymentAmount; //Math.round(0.70 * Variables.glblDuePrice)

    //alert('restDueAmount ==> ',restDueAmount)
    //Variables.glblRestDuePrice = restDueAmount
    setGlobalVariableValue({ key: 'glblRestDuePrice', value: restDueAmount });
    let thanksMsg =
      'Thank you for your payment of ' +
      dwonPaymentAmount +
      ' € it has been accepted.\r\r' +
      ' The remaining amount to pay, at least 30 days before your arrival is of : ' +
      restDueAmount +
      ' €';
    setMsgRestToPay(thanksMsg);

    let paymntTxtInstr =
      'You will be redirected to Stripe Inc our payments processing partner. You can either' +
      ' pay your 650€ down payments and pay the rest 30 days before your arrival Or replace it with the ' +
      ' total due booking amount mentionned above. Please use the same email account as that used for your login,' +
      ' in order to receive your payments invoices & receipts. ';

    setPaymentInstructions(paymntTxtInstr);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        assignDuePrice(Variables, setGlobalVariableValue);
        const _stripeSessCreaJson = (
          await XanoChkOutStripeApi.chkOutSessionPOST(Constants, {
            price_id: Constants['dpPrice_id'],
          })
        )?.json;
        setPaymentSessParams(setGlobalVariableValue, _stripeSessCreaJson);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: palettes['App Fire'].myLightBlue,
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        backgroundColor={palettes['App Fire'].TripItBlue}
        source={imageSource(Images['IMG0001'])}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', marginTop: 45, width: '100%' },
            dimensions.width
          )}
        >
          {/* Left Side Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Icon Flex Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexGrow: 0,
                    flexShrink: 0,
                    paddingBottom: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Icon  */}
                <Icon
                  size={24}
                  color={palettes['Brand Fire'].Strong_Inverse}
                  name={'Ionicons/arrow-undo-sharp'}
                />
              </View>
            </Touchable>
          </View>
          {/* Middle Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0, justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* ScrTitle */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes['Brand Fire'].Strong_Inverse,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 22,
                  marginTop: 11,
                },
                dimensions.width
              )}
            >
              {'Payment'}
            </Text>
          </View>
          {/* Right Side Frame */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center', paddingLeft: 9, paddingRight: 9 },
              dimensions.width
            )}
          ></View>
        </View>
        {/* Timeline */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: 16,
              marginTop: 5,
              paddingBottom: 16,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 16,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 16,
                marginRight: 16,
                paddingBottom: 4,
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 2,
                  borderColor: theme.colors.text.strong,
                  borderLeftWidth: 2,
                  borderRadius: 6,
                  borderRightWidth: 2,
                  borderTopWidth: 2,
                  height: 12,
                  width: 12,
                },
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.text.strong,
                  flex: 1,
                  height: 2,
                },
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.text.strong,
                  borderRadius: 6,
                  height: 12,
                  width: 12,
                },
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.text.strong,
                  flex: 1,
                  height: 2,
                },
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 2,
                  borderColor: theme.colors.text.strong,
                  borderLeftWidth: 2,
                  borderRadius: 6,
                  borderRightWidth: 2,
                  borderTopWidth: 2,
                  height: 12,
                  width: 12,
                },
                dimensions.width
              )}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 10,
                paddingRight: 10,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 10,
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {'personal info'}
            </Text>

            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 10,
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {'payment'}
            </Text>

            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 10,
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {'Booking ticket'}
            </Text>
          </View>
        </View>
        {/* Booking global price */}
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: 5, marginLeft: 22 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.strong,
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 16,
              },
              dimensions.width
            )}
          >
            {'Booking global price  : '}
            {Constants['glblDuePrice']}
            {' €'}
          </Text>
        </View>
        {/* Down payment to pay now */}
        <View style={StyleSheet.applyWidth({ marginTop: 5 }, dimensions.width)}>
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.text.strong,
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 16,
                marginLeft: 22,
              },
              dimensions.width
            )}
          >
            {'Due down payment   : '}
            {calcDownPaymentPrice(Variables, Constants['glblDuePrice'])}
            {' €'}
          </Text>
        </View>
        <>
          {!!(Platform.OS === 'web') ? null : (
            <WebView
              allowFileAccessFromFileURLs={false}
              allowUniversalAccessFromFileURLs={false}
              javaScriptCanOpenWindowsAutomatically={false}
              javaScriptEnabled={true}
              mediaPlaybackRequiresUserAction={false}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              startInLoadingState={false}
              {...GlobalStyles.WebViewStyles(theme)['Web View'].props}
              cacheEnabled={false}
              incognito={true}
              source={imageSource(
                'https://buy.stripe.com/test_6oEdR0fZ9dv5fny288?locale=en'
              )}
              style={StyleSheet.applyWidth(
                GlobalStyles.WebViewStyles(theme)['Web View'].style,
                dimensions.width
              )}
            />
          )}
        </>
        <>
          {!(Platform.OS === 'web') ? null : (
            <Shadow
              offsetX={0}
              offsetY={0}
              showShadowCornerBottomEnd={true}
              showShadowCornerBottomStart={true}
              showShadowCornerTopEnd={true}
              showShadowCornerTopStart={true}
              showShadowSideBottom={true}
              showShadowSideEnd={true}
              showShadowSideStart={true}
              showShadowSideTop={true}
              paintInside={false}
              style={StyleSheet.applyWidth(
                {
                  borderRadius: 11,
                  height: 321,
                  marginBottom: 35,
                  marginLeft: 15,
                  marginTop: 35,
                  width: '96%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    margin: 5,
                    marginBottom: 25,
                    marginTop: 25,
                    padding: 5,
                    width: '98%',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_400Regular' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Down payment Instructions'}
                </Text>
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  placeholder={
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                  }
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
                  numberOfLines={9}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                      { marginBottom: 15, marginTop: 15, width: '90%' }
                    ),
                    dimensions.width
                  )}
                  value={paymentInstructions}
                />
                {/* Button Proceed2Payment */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          `${Constants['paymentUrl']}`
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: theme.colors.text.strong,
                        borderBottomWidth: 2,
                        borderColor: palettes['Brand Fire'].Strong_Inverse,
                        borderLeftWidth: 1,
                        borderRadius: 22,
                        borderRightWidth: 3,
                        borderTopWidth: 1,
                        fontFamily: 'Poppins_300Light',
                        marginTop: 7,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'  1.  Proceed to Payments   '}
                />
              </View>
            </Shadow>
          )}
        </>
        {/* Button  paymentReceipt */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const _RtrndStripeSessJson = (
                  await XanoChkOutStripeApi.stripeSessionByIdGET(Constants, {
                    id: Constants['paymentSessId'],
                  })
                )?.json;
                const _payment_status = _RtrndStripeSessJson?.payment_status;

                showAlertUtil({
                  title: 'payment status?',
                  message: _payment_status,
                  buttonText: 'OK',
                });

                if (_payment_status === 'unpaid') {
                  return;
                }
                if (_payment_status !== 'unpaid') {
                  showAlertUtil({
                    title: 'Your down payment :',
                    message: msgRestToPay,
                    buttonText: 'OK',
                  });
                }
                const _resaCreatedJson = (
                  await VillaVuXanoApi.newReservationPOST(Constants, {
                    bookedEnd: Constants['glblEndOfBooking'],
                    bookedStart: Constants['glblStartOfBooking'],
                    bookingfee: Constants['VillaVals']?.booking_price,
                    nightlyprice: Constants['VillaVals']?.nightly_price,
                    timenow: '2023-05-23T23:20:00',
                    totalnights: Constants['glblNightsBooked'],
                    totalpersons: Constants['glblPersonsBooked'],
                    totalprice: 2100,
                    villaId: Constants['VillaId'],
                  })
                )?.json;
                const _reservationId = _resaCreatedJson?.id;
                (
                  await VillaVuXanoApi.newResaDownPaymentPOST(Constants, {
                    downpaid: 'paid',
                    downpymnt: 650,
                    resaId: _reservationId,
                    restdue: 1450,
                    restpaid: 'tobepaid',
                    totaldue: 2100,
                    totalpaid: 'no',
                  })
                )?.json;
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.text.strong,
              borderBottomWidth: 2,
              borderColor: palettes['Brand Fire'].Strong_Inverse,
              borderLeftWidth: 1,
              borderRadius: 21,
              borderRightWidth: 3,
              borderTopWidth: 1,
              color: palettes['Brand Fire'].Strong_Inverse,
              fontFamily: 'Poppins_600SemiBold',
              height: 40,
              marginBottom: 25,
              marginLeft: '34%',
              marginRight: 11,
              marginTop: 15,
              textAlign: 'center',
              width: '65%',
            },
            dimensions.width
          )}
          title={'2. Reclaim payment receipt'}
        />
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(PaymentCardInfoScreen);
